import { memo } from 'react';

import ActionsSummary from '@/src/modules/InsightsModule/components/ActionsSummary';
import ContactsGraph from '@/src/modules/InsightsModule/components/ContactsGraph';

import ContactsSummary from '@/modules/InsightsModule/components/ContactsSummary';

const InsightsMain = () => {
  return (
    <div className="flex min-h-svh flex-col flex-wrap gap-4 bg-emblue-secondary-hover px-8 py-4 align-baseline">
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-3">
          <ContactsSummary />
        </div>
        <div className="col-span-2">
          <ContactsGraph />
        </div>
      </div>
      <ActionsSummary />
    </div>
  );
};

export default memo(InsightsMain);
