import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { ContactsGraphTooltip } from '@/src/modules/InsightsModule/components/ContactsGraph/components/ContactsGraphTooltip';
import { formatNumberToMetricPrefix } from '@/src/utils/FormatNumberToMetricPrefix';

import { atomGraphEvolutionList } from '@/modules/InsightsModule/atoms/InsightsAtom';
import { BAR_GRAPH_CONFIG, TBarRadius } from '@/modules/InsightsModule/constants';

const BarGraphContacts = () => {
  const graphEvolutionList = useAtomValue(atomGraphEvolutionList);

  return (
    <div className="h-[400px] w-full">
      <ResponsiveContainer height="100%" width="100%">
        <BarChart data={graphEvolutionList}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis axisLine={false} dataKey="name" style={BAR_GRAPH_CONFIG.labels} tickLine={false} />
          <YAxis
            axisLine={false}
            style={BAR_GRAPH_CONFIG.labels}
            tickFormatter={(value: number) => formatNumberToMetricPrefix(Number(value))}
            tickLine={false}
          />
          <Tooltip content={<ContactsGraphTooltip />} cursor={{ fill: 'transparent' }} />
          <Bar
            barSize={BAR_GRAPH_CONFIG.bar.barSize}
            dataKey="contacts"
            fill={BAR_GRAPH_CONFIG.bar.fill.contacts}
            radius={BAR_GRAPH_CONFIG.bar.radius as TBarRadius}
          />
          <Bar
            barSize={BAR_GRAPH_CONFIG.bar.barSize}
            dataKey="contacted"
            fill={BAR_GRAPH_CONFIG.bar.fill.contacted}
            radius={BAR_GRAPH_CONFIG.bar.radius as TBarRadius}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default memo(BarGraphContacts);
