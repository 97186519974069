import classNames from 'classnames';
import { memo, MouseEventHandler, ReactElement, useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { IconSvg, If, Text } from '@/lib/v2/components';
import { ArrowBackIcon, EditPencilIconNew } from '@/lib/v2/icons/solid';

interface To {
  path: string;
  params?: NavigateOptions;
}

export interface CustomPaths {
  id: string;
  name: string;
  to?: To;
}

interface BreadcrumbProps {
  actionButtons?: ReactElement;
  customPaths: CustomPaths[];
  handleOnBackClick?: () => void;
  withoutBackArrow?: boolean;
  withoutMargin?: boolean;
  itemId?: number;
  isEditable?: boolean;
  onEditableClick?: MouseEventHandler<HTMLElement>;
  isLoading?: boolean;
}

const Breadcrumb = ({
  actionButtons,
  customPaths,
  handleOnBackClick,
  withoutBackArrow = false,
  withoutMargin = false,
  itemId,
  isEditable,
  onEditableClick,
  isLoading = false,
}: BreadcrumbProps) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (to?: To) => {
      if (to) {
        navigate(to.path, to.params);
      }
    },
    [navigate]
  );

  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };

  const customPathItems = customPaths.map((item: CustomPaths, index: number) => {
    const { name, to, id } = item;
    const isLast = index === customPaths.length - 1;
    const title = name && name.charAt(0).toUpperCase() + name.slice(1);
    const textClassName = classNames(
      'flex flex-row items-center bg-transparent font-normal hover:font-bold',
      isLast ? 'cursor-default' : 'pointer'
    );

    return (
      <If key={item.name} condition={!!title}>
        <div className="ml-1 flex flex-row items-center">
          <Text
            className={textClassName}
            fontWeight={isLast ? 'bold' : 'normal'}
            id={id}
            variant="text"
            onClick={() => handleOnClick(to)}
          >
            {index === 0 ? truncateString(title, 25) : `/ ${truncateString(title, 25)}`}
          </Text>
        </div>
      </If>
    );
  });

  const wrapperClass = classNames(
    'flex h-[48px] w-full flex-row place-content-between items-center bg-white px-8 shadow-md',
    {
      'mb-6': !withoutMargin,
    }
  );

  if (isLoading) {
    return (
      <div className={wrapperClass} data-testid="breadcrumb">
        <div className="flex flex-row items-center">
          <div className="h-4 w-96 animate-pulse rounded-md bg-gray-300"></div>
        </div>
      </div>
    );
  }

  return (
    <div className={wrapperClass} data-testid="breadcrumb">
      <div className="flex flex-row items-center">
        <If condition={!withoutBackArrow}>
          <IconSvg
            data-testid="breadcrumb-arrow"
            height="26px"
            id="breadcrumb-arrow-back"
            svgComponent={<ArrowBackIcon />}
            width="22px"
            onClick={handleOnBackClick}
          />
        </If>
        <div className="ml-2 flex flex-row items-center" data-testid="breadcrumb-paths">
          {customPathItems}
          <If condition={isEditable}>
            <IconSvg
              className="ml-3"
              fillColor="gray-dark"
              height="16px"
              id="breadcrumb-item-edit"
              strokeColor="gray-dark"
              svgComponent={<EditPencilIconNew />}
              width="16px"
              onClick={onEditableClick}
            />
          </If>
          <If condition={!!itemId}>
            <Text
              className="ml-3 flex flex-row items-center"
              fontWeight={'normal'}
              id="breadcrumb-item-id"
              variant="text"
            >
              - ID {itemId}
            </Text>
          </If>
        </div>
      </div>
      <If condition={!!actionButtons}>
        <div className="flex flex-row" data-testid="breadcrumb-buttons">
          {actionButtons}
        </div>
      </If>
    </div>
  );
};

export default memo(Breadcrumb);
