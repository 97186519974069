import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import RouterSkeleton from '@/src/modules/AutomationModuleNew/components/RouterSkeleton';
import { AUTOMATION_PATHS } from '@/src/modules/AutomationModuleNew/constants';
import CatalogTemplatesLayout from '@/src/modules/CampaignsModule/layouts/CatalogTemplatesLayout';
import { CatalogTemplates } from '@/src/modules/CampaignsModule/screens/CatalogTemplates';
import { MyTemplates } from '@/src/modules/CampaignsModule/screens/MyTemplates';

import HtmlEditor from '@/modules/CampaignsModule/components/HtmlUpload/HtmlEditor';
import ActionContentRouter from '@/modules/CampaignsModule/layouts/ActionContentRouter';
import CampaignWithWrapperLayout from '@/modules/CampaignsModule/layouts/CampaignWithWrapperLayout';
import { ActionPreview } from '@/modules/CampaignsModule/screens/ActionPreview';
import { EditorContent } from '@/modules/CampaignsModule/screens/EditorContent';

const EditorContentWrapperLayout = CampaignWithWrapperLayout(() => <EditorContent isAutomation />);

const AutomationHome = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/AutomationHome/AutomationHome')
);

const RulesHome = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/RulesHome/RulesHome')
);

const RecurringMessagesHome = lazy(
  () =>
    import('@/src/modules/AutomationModuleNew/screens/RecurringMessagesHome/RecurringMessagesHome')
);

const JourneysHome = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/JourneysHome/JourneysHome')
);

const FlowDetail = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/FlowDetail/FlowDetail')
);

const CreateOrEditEvent = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/CreateOrEditEvent/CreateOrEditEvent')
);

const RecurringMessageReport = lazy(
  () =>
    import(
      '@/src/modules/AutomationModuleNew/screens/RecurringMessageReport/RecurringMessageReport'
    )
);

const RecurringMessageDetail = lazy(
  () =>
    import(
      '@/src/modules/AutomationModuleNew/screens/RecurringMessageDetail/RecurringMessageDetail'
    )
);

const ActionRecurringMessage = lazy(
  () =>
    import(
      '@/src/modules/AutomationModuleNew/screens/ActionRecurringMessage/ActionRecurringMessage'
    )
);

const EditRecurringMessage = lazy(
  () =>
    import('@/src/modules/AutomationModuleNew/screens/EditRecurringMessage/EditRecurringMessage')
);

export const AutomationModuleNewRouter = () => {
  return (
    <Suspense fallback={<RouterSkeleton />}>
      <Routes>
        <Route index element={<AutomationHome />} />
        <Route element={<RulesHome />} path={`${AUTOMATION_PATHS.RULES_PATH}`} />
        <Route element={<RecurringMessagesHome />} path={`${AUTOMATION_PATHS.RECURRENCES_PATH}`} />
        <Route
          element={<RecurringMessageDetail />}
          path={`${AUTOMATION_PATHS.RECURRENCES_PATH}/:campaignId`}
        />
        <Route
          element={<ActionRecurringMessage />}
          path={`${AUTOMATION_PATHS.RECURRENCES_PATH}/:campaignId/${AUTOMATION_PATHS.ACTION_REPORT_PATH}/:actionId`}
        />
        <Route
          element={<EditRecurringMessage />}
          path={`${AUTOMATION_PATHS.RECURRENCES_PATH}/:campaignId/${AUTOMATION_PATHS.CONFIG}/:actionId`}
        />
        <Route
          element={<HtmlEditor isAutomationModule />}
          path={`${AUTOMATION_PATHS.RECURRENCES_PATH}/:campaignId/${AUTOMATION_PATHS.CONFIG}/:actionId/:actionType/html-editor`}
        />
        <Route
          element={<ActionPreview />}
          path={`:campaignId/action/:actionId/:actionType/${AUTOMATION_PATHS.PREVIEW}`}
        />
        <Route
          element={<EditorContentWrapperLayout />}
          path={`:campaignId/action/:actionId/:actionType/${AUTOMATION_PATHS.EDITOR}`}
        />
        <Route
          element={<RecurringMessageReport />}
          path={`${AUTOMATION_PATHS.RECURRENCES_PATH}/:id/${AUTOMATION_PATHS.REPORT_PATH}`}
        />
        <Route element={<JourneysHome />} path={AUTOMATION_PATHS.JOURNEYS_PATH} />
        <Route
          element={<FlowDetail />}
          path={`${AUTOMATION_PATHS.JOURNEYS_PATH}/${AUTOMATION_PATHS.FLOW_DETAIL_PATH}/:guid`}
        />
        <Route
          element={<ActionContentRouter />}
          path={`${AUTOMATION_PATHS.JOURNEYS_PATH}/:campaignId/action/:actionId/:actionType/*`}
        />
        <Route
          element={<CreateOrEditEvent isEditView />}
          path={`${AUTOMATION_PATHS.JOURNEYS_PATH}/${AUTOMATION_PATHS.EVENT_PATH}/:eventId`}
        />
        <Route
          element={<CreateOrEditEvent />}
          path={`${AUTOMATION_PATHS.JOURNEYS_PATH}/${AUTOMATION_PATHS.CREATE_EVENT_PATH}`}
        />
        <Route
          element={<CatalogTemplatesLayout basePath={`/v2/${AUTOMATION_PATHS.MODULE_PATH}`} />}
          path={'catalogs'}
        >
          <Route element={<CatalogTemplates />} path="email/:actionId" />
          <Route element={<MyTemplates />} path="email/:actionId/my-templates" />
        </Route>
      </Routes>
    </Suspense>
  );
};
