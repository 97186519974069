'use client';
import { memo } from 'react';

const BarGraphSkeleton = () => {
  return (
    <div className="h-[400px] w-full">
      <div className="flex size-full gap-2 px-8 py-4">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="flex size-full items-end gap-2">
            <div
              className="w-8 animate-pulse rounded-t-md bg-gray-300"
              style={{
                height: `${Math.random() * (80 - 40) + 40}%`,
              }}
            />
            <div
              className="w-8 animate-pulse rounded-t-md bg-gray-200"
              style={{
                height: `${Math.random() * (80 - 40) + 40}%`,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(BarGraphSkeleton);
