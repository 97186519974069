import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { WhatsAppTemplateCreate } from '@/src/infrastructure/Protocol/Inbox/IInboxService';
import { useInboxService } from '@/src/infrastructure/Protocol/Inbox/useInboxService';

import {
  atomCurrentWhatsAppMessage,
  atomIsTemplateCreated,
  atomMediaUrl,
  atomTemplateOption,
} from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { TEMPLATE_OPTION } from '@/modules/CampaignsModule/components/actionManagement/WhatsAppAction/constants';
import { TRANSLATE_WHATSAPP_ACTION_FORM } from '@/modules/CampaignsModule/constants';

export interface IWhatsAppTemplateForm {
  title: string;
  media: string;
  message: string;
}

export const useWhatsAppTemplateForm = () => {
  const { t } = useTranslation('inbox');
  const inboxService = useInboxService();

  const setCurrentMessage = useSetAtom(atomCurrentWhatsAppMessage);
  const setMediaUrl = useSetAtom(atomMediaUrl);
  const setTemplateOption = useSetAtom(atomTemplateOption);
  const setIsTemplateCreated = useSetAtom(atomIsTemplateCreated);

  const { control, handleSubmit, setValue, getValues } = useForm<IWhatsAppTemplateForm>();

  const RULES = {
    title: {
      required: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.RULES.input`),
      pattern: {
        value: /^[a-z\d_]+$/,
        message: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.RULES.title_format`),
      },
    },
    media: {
      pattern: {
        value: /^https?:\/\/.*\.(jpg|jpeg|png)$/i,
        message: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.RULES.media_format`),
      },
    },
    message: {
      required: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.RULES.input`),
      maxLength: {
        value: 1024,
        message: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.RULES.message_max_length`),
      },
    },
  };

  const handleDismissTemplate = useCallback(() => {
    setTemplateOption(TEMPLATE_OPTION.IDLE);
    setCurrentMessage('');
    setMediaUrl('');
    setValue('message', '');
  }, [setTemplateOption, setCurrentMessage, setMediaUrl, setValue]);

  const handleSubmitTemplate = useCallback(
    async (data: IWhatsAppTemplateForm) => {
      if (!data.title || !data.message) {
        toast({
          title: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.TOAST_MESSAGES.validation.error.title`),
          body: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.TOAST_MESSAGES.validation.error.body`),
          variant: 'error',
        });
        return;
      }

      try {
        const variableRegex = /\$\{\d+#[^}]+\}/g;
        const variables: Record<string, string> = {};
        let content = data.message;
        let index = 1;

        content = content.replace(variableRegex, (match) => {
          variables[index.toString()] = match;
          const replacement = `{{${index}}}`;
          index++;
          return replacement;
        });

        const templateData: WhatsAppTemplateCreate = {
          name: data.title,
          language: 'es',
          variables: variables,
          content: content,
        };

        let response;

        if (data.media) {
          templateData.media = data.media;
          response = await inboxService.createMediaTemplate(templateData);
        } else {
          response = await inboxService.createTextTemplate(templateData);
        }

        if (response?.template?.sid) {
          toast({
            title: t(
              `${TRANSLATE_WHATSAPP_ACTION_FORM}.TOAST_MESSAGES.createTemplate.success.title`
            ),
            body: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.TOAST_MESSAGES.createTemplate.success.body`),
            variant: 'success',
          });
          setIsTemplateCreated(true);
          return true;
        } else {
          throw new Error('Template creation failed');
        }
      } catch (error) {
        toast({
          title: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.TOAST_MESSAGES.createTemplate.error.title`),
          body: t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.TOAST_MESSAGES.createTemplate.error.body`),
          variant: 'error',
        });
        console.error('Error submitting template:', error);
        return false;
      } finally {
        handleDismissTemplate();
      }
    },
    [t, inboxService, handleDismissTemplate, setIsTemplateCreated]
  );

  return {
    control,
    handleSubmitTemplate: handleSubmit(handleSubmitTemplate),
    setValue,
    getValues,
    RULES,
  };
};
