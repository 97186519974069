import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Alert, Checkbox, Flex, If, Text } from '@/lib/v2/components';

import Skeleton from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/components/Skeleton/Skeleton';
import { IWhatsAppFormInfo } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/hooks/useWhatsAppForm';
import { useWhatsAppFormEvents } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/hooks/useWhatsAppFormEvents';
import {
  TEMPLATE_OPTION,
  TemplateOption,
} from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/constants';
import { TRANSLATE_WHATSAPP_ACTION_FORM } from '@/src/modules/CampaignsModule/constants';
import { IFormProps } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import { TemplateCard } from './components/TemplateCard';
import { TemplateOptions } from './components/TemplateOptions';
import { WhatsAppTemplateInputs } from './components/WhatsAppTemplateInputs';
import { useTemplateManagement } from './hooks/useTemplateManagement';

import {
  atomActionInfoWhatsApp,
  atomIsTemplateCreated,
  atomMessageWhatsApp,
  atomTemplateOption,
} from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { SendWhatsAppModal } from '@/modules/ContactsModule/components/SendWhatsAppModal';

interface IMessageInputProps {
  loading?: boolean;
  form: IFormProps<IWhatsAppFormInfo>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const MessageInput = ({ loading, form, rules, disabled = false }: IMessageInputProps) => {
  const { t } = useTranslation('inbox');
  const { actionId } = useParams();

  const [templateOption, setTemplateOption] = useAtom(atomTemplateOption);
  const [isTemplateCreated, setIsTemplateCreated] = useAtom(atomIsTemplateCreated);

  const [messageWhatsApp, setMessageWhatsApp] = useAtom(atomMessageWhatsApp);
  const actionInfoWhatsApp = useAtomValue(atomActionInfoWhatsApp);
  const { setInfoOnEvent } = useWhatsAppFormEvents({ actionId: Number(actionId) });

  const { control, setValue } = form;

  const {
    isLoadingTemplates,
    selectedTemplate,
    modalIsOpen,
    handleCloseModal,
    handleOpenModal,
    handleDeleteTemplate,
  } = useTemplateManagement({
    setValue,
    setInfoOnEvent,
    templateOption,
  });

  /* cspell:disable */
  useEffect(() => {
    const message = actionInfoWhatsApp?.mensaje ?? '';

    setValue('message', message);
    setMessageWhatsApp({ ...messageWhatsApp, original: message });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionInfoWhatsApp?.mensaje, setMessageWhatsApp, setValue]);
  /* cspell:enable */

  useEffect(() => {
    setTimeout(() => {
      if (isTemplateCreated) {
        setIsTemplateCreated(false);
      }
    }, 15000);
  }, [isTemplateCreated, setIsTemplateCreated]);

  return (
    <>
      <div>
        <Text
          className="sticky top-0 z-[1] border-b border-solid border-emblue-borders bg-emblue-white pb-2"
          color="primary"
          fontWeight="medium"
          variant="sub-headline"
        >
          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.message`)}
        </Text>
        <Text className="mt-4" color="dark" fontWeight="normal" variant="text">
          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.messageDisclaimer`)}
        </Text>

        <div className="mb-10">
          <If condition={isLoadingTemplates}>
            <Skeleton className="mt-9 h-10 w-full" />
          </If>
          <Flex
            withGap
            className={templateOption === TEMPLATE_OPTION.CREATE ? 'mt-4' : 'mt-9'}
            gapSize="small"
            itemAlignment="start"
            textAlignment="center"
          >
            <If condition={isLoadingTemplates}>
              <Skeleton className="mt-4 h-5 w-32" />
            </If>
            <If condition={!isLoadingTemplates}>
              <If condition={templateOption === TEMPLATE_OPTION.IDLE && !messageWhatsApp.original}>
                <TemplateOptions
                  disabled={disabled}
                  isTrackingEnabled={messageWhatsApp.track}
                  onCreateTemplate={() =>
                    setTemplateOption(TEMPLATE_OPTION.CREATE as TemplateOption)
                  }
                  onSelectTemplate={handleOpenModal}
                />
              </If>
              <If condition={!!messageWhatsApp.original && templateOption === TEMPLATE_OPTION.IDLE}>
                <TemplateCard
                  templateName={selectedTemplate?.friendly_name ?? ''}
                  onDelete={handleDeleteTemplate}
                />
              </If>
              <If condition={templateOption === TEMPLATE_OPTION.CREATE}>
                <WhatsAppTemplateInputs
                  actionId={Number(actionId)}
                  disabled={disabled}
                  loading={loading}
                />
              </If>
            </If>
          </Flex>
          <If condition={messageWhatsApp?.customFields > 0}>
            <Alert
              className="mt-4"
              description={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.messageDisclaimerCustomFields`)}
              type="info"
            />
          </If>
        </div>
        <If condition={!messageWhatsApp.original}>
          <div className="-mt-1 flex w-full justify-start">
            <Checkbox
              control={control}
              id="whatsapp_template_checkbox"
              name="template"
              rules={rules.template}
              type="hidden"
            />
          </div>
        </If>
        <If condition={isTemplateCreated}>
          <Text className="mt-4" color="tertiary" fontWeight="normal" variant="text">
            {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.messageSuccessTemplateCreate`)}
          </Text>
        </If>
      </div>
      <SendWhatsAppModal isOpen={modalIsOpen} onClose={handleCloseModal} />
    </>
  );
};

export default MessageInput;
