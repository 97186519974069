import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomActionNodeInfo } from '@/modules/RulesModule/atoms/rules';

const useActionNodeInfo = () => {
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const setActionNodeInfo = useSetAtom(atomActionNodeInfo);
  const { emailInfo, content, sender } = actionInfoEmail || {};

  useEffect(() => {
    const isComplete = Boolean(content?.message && emailInfo?.subject && sender?.emailFrom);
    setActionNodeInfo({ partial: !isComplete, complete: isComplete, empty: !sender?.emailFrom });
  }, [content?.message, emailInfo?.subject, sender?.emailFrom, setActionNodeInfo]);
};

export default useActionNodeInfo;
