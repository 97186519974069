import BeePlugin from '@mailupinc/bee-plugin';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  atomBeefreeDisabled,
  atomBeefreePreview,
  atomBeefreePristine,
  atomBeefreeSaved,
  atomBeefreeSaving,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { ActionTypeName } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { usePreventExit } from './usePreventExit';
import { useSendEmailTest } from './useSendEmailTest';

import { AUTOMATION_PATHS } from '@/modules/AutomationModuleNew/constants';
import { atomNewRulePath } from '@/modules/RulesModule/atoms/rules';

export const useSendPreview = (isAutomation = false) => {
  const navigate = useNavigate();
  const [newRulePath, setNewRulePath] = useAtom(atomNewRulePath);
  const { handleOpenPrevent } = usePreventExit();
  const pristine = useAtomValue(atomBeefreePristine);
  const { sendTestEmail } = useSendEmailTest();
  const { actionType, campaignId, actionId } = useParams();

  const [showSendPreview, setShowSendPreview] = useState(false);

  const handleClosePreview = useCallback(() => setShowSendPreview(false), []);

  const backUrlMap = useMemo<{ [K in ActionTypeName]?: string }>(
    () => ({
      master: `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.RECURRENCES_PATH}/${campaignId}/${AUTOMATION_PATHS.CONFIG}/${actionId}`,
      'email-trigger': `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.JOURNEYS_PATH}/${campaignId}/action/${actionId}/${actionType}/email`,
    }),
    [actionId, actionType, campaignId]
  );

  const goToBack = useCallback(() => {
    if (isAutomation) {
      const backUrl =
        backUrlMap[actionType as ActionTypeName] || `/v2/${AUTOMATION_PATHS.MODULE_PATH}}`;
      if (pristine) {
        handleOpenPrevent(backUrl);
        return;
      }
      navigate(backUrl);
      return;
    }

    if (pristine) {
      const url = window.location.href.replace('/editor', '').replace(window.location.origin, '');
      handleOpenPrevent(url);
      return;
    }

    let newUrl = window.location.pathname.replaceAll('/editor', '');

    if (newRulePath) {
      newUrl = window.location.pathname.replaceAll('/editor', '/email');
      setNewRulePath(false);
    }
    navigate(newUrl);
  }, [
    actionType,
    backUrlMap,
    handleOpenPrevent,
    isAutomation,
    navigate,
    newRulePath,
    pristine,
    setNewRulePath,
  ]);

  const handleOpenPreview = useCallback(() => {
    setShowSendPreview(true);
  }, []);

  return {
    handleOpenPreview,
    handleClosePreview,
    goToBack,
    showSendPreview,
    sendTestEmail,
  };
};

interface IUseMergeTags {
  beefree?: BeePlugin;
}

export const useMergeTags = ({ beefree }: IUseMergeTags) => {
  const [showMergeTags, setShowMergeTags] = useState(false);

  const toggleMergeTags = useCallback(() => {
    beefree?.toggleMergeTagsPreview();
    setShowMergeTags(!showMergeTags);
  }, [beefree, showMergeTags]);

  return {
    toggleMergeTags,
    showMergeTags,
  };
};

interface IUsePreview {
  beefree?: BeePlugin;
}

export const usePreview = ({ beefree }: IUsePreview) => {
  const [showPreview, setShowPreview] = useAtom(atomBeefreePreview);

  const togglePreview = useCallback(() => {
    beefree?.togglePreview();
    setShowPreview(!showPreview);
  }, [beefree, setShowPreview, showPreview]);

  const clearPreview = useCallback(() => {
    setShowPreview(false);
  }, [setShowPreview]);

  return {
    togglePreview,
    showPreview,
    clearPreview,
  };
};

interface IUseStructure {
  beefree?: BeePlugin;
}
export const useStructure = ({ beefree }: IUseStructure) => {
  const [showStructure, setShowStructure] = useState(false);

  const toggleStructure = useCallback(() => {
    beefree?.toggleStructure();
    setShowStructure(!showStructure);
  }, [beefree, showStructure]);

  return {
    showStructure,
    toggleStructure,
  };
};

interface IUseSaveMail {
  beefree?: BeePlugin;
}

export const useEmailSave = ({ beefree }: IUseSaveMail) => {
  const setBeefreeSaving = useSetAtom(atomBeefreeSaving);
  const setBeefreeSaved = useSetAtom(atomBeefreeSaved);
  const setBeefreeDisabled = useSetAtom(atomBeefreeDisabled);
  const save = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      try {
        setBeefreeSaving(true);
        setBeefreeSaved(false);
        setBeefreeDisabled(true);
        localStorage.removeItem('@beefree/template.create');
        beefree?.save();
        resolve();
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  }, [beefree, setBeefreeDisabled, setBeefreeSaved, setBeefreeSaving]);
  return {
    save,
  };
};

export const useEmailSaveTemplate = ({ beefree }: IUseSaveMail) => {
  const saveTemplate = useCallback(() => {
    localStorage.setItem('@beefree/template.create', 'true');
    beefree?.saveAsTemplate();
    beefree?.save();
  }, [beefree]);

  return {
    saveTemplate,
  };
};
