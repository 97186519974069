import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const ebInfoCookie = document.cookie.split('; ').find((row) => row.startsWith('eb_info='));

  if (ebInfoCookie) {
    config.headers['X-Session-Info'] = ebInfoCookie;
  }

  return config;
});

export default axiosInstance;
