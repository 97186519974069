import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Button, If } from '@/lib/v2/components';
import { ModalSingleInput } from '@/lib/v2/organisms';

import {
  atomActionNodeInfo,
  atomCallbackActivateRule,
  atomCallbackPauseRule,
  atomCallbackSaveRule,
  atomDisableEditRule,
  atomIsLoadingRuleData,
  atomIsTriggerFormComplete,
  atomToggleActivateRuleModal,
} from '@/src/modules/RulesModule/atoms/rules';
import {
  TRANSLATE_RULES_CALL_TO_ACTION,
  TRANSLATE_RULES_RENAME,
} from '@/src/modules/RulesModule/constants';
import { useRuleStatus } from '@/src/modules/RulesModule/hooks/useRuleStatus';

import BackToRulesModal from './components/BackToRulesModal';

import { AUTOMATION_PATHS } from '@/modules/AutomationModuleNew/constants';
import {
  atomActionInfoEmail,
  atomProcessingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomToggleEditActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';
import { useCampaignLastPath } from '@/modules/CampaignsModule/hooks/useCampaignLastPath';
import { useEditActions } from '@/modules/CampaignsModule/hooks/useEditActions';
import { isActionEditable } from '@/modules/CampaignsModule/utils';
import { ActivateAction } from '@/modules/RulesModule/components/ActivateAction';

const HeaderSectionRules = () => {
  const { t } = useTranslation('rules');
  const { t: tn } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { campaignId, actionId } = useParams();

  const [toggleBackToRulesModal, setToggleBackToRulesModal] = useState<boolean>(false);

  const setToggleActivateRuleModal = useSetAtom(atomToggleActivateRuleModal);
  const setDisableEditRule = useSetAtom(atomDisableEditRule);
  const callbackSaveRule = useAtomValue(atomCallbackSaveRule);
  const callbackActivateRule = useAtomValue(atomCallbackActivateRule);
  const callbackPauseRule = useAtomValue(atomCallbackPauseRule);
  const isTriggerFormComplete = useAtomValue(atomIsTriggerFormComplete);
  const actionNodeInfo = useAtomValue(atomActionNodeInfo);
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const processingAction = useAtomValue(atomProcessingAction);
  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);
  const [toggleEditActionsModal, setToggleEditActionsModal] = useAtom(atomToggleEditActionsModal);
  const { pathname } = useLocation();
  const isAutomation = pathname.includes('automation');

  const { action, refetch } = useActionData(Number(actionId));
  const { isRuleActive } = useRuleStatus();
  const {
    state: { VALIDATION_RULES, loading },
    events: { handleApplyRename, handleCloseModal, handleValidation },
  } = useEditActions({ campaignId, actionId });

  useEffect(() => {
    //TODO: Review this refetch & atom for update data
    refetch();
    setDisableEditRule(!isActionEditable(Number(action?.status)));
  }, [action?.status, actionId, refetch, setDisableEditRule]);

  const lastParam = useMemo(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    return pathSegments[pathSegments.length - 1];
  }, [location]);

  const ruleEmailPath = useMemo(() => lastParam === 'email', [lastParam]);

  const handleOpenModal = useCallback(() => {
    setToggleEditActionsModal(true);
  }, [setToggleEditActionsModal]);

  const handleFinishRule = useCallback(() => {
    if (isAutomation) {
      navigate(`/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.JOURNEYS_PATH}`);
      return;
    }
    navigate(`${CAMPAIGNS_PATH}/${String(campaignId)}`);
  }, [campaignId, isAutomation, navigate]);

  const handleCallbackContinue = useCallback(() => {
    callbackSaveRule && void callbackSaveRule();
  }, [callbackSaveRule]);

  const handleCallbackActivate = useCallback(() => {
    callbackActivateRule && void callbackActivateRule();
  }, [callbackActivateRule]);

  const handleCallbackPause = useCallback(() => {
    callbackPauseRule && void callbackPauseRule();
  }, [callbackPauseRule]);

  const { campaignLastPath } = useCampaignLastPath();

  /* cspell:disable*/
  const headerRulesPaths = useMemo(() => {
    const breadcrumbLinksAutomation = [
      {
        id: 'automation-path',
        name: tn('BREADCRUMB.automation'),
        to: { path: `/v2/${AUTOMATION_PATHS.MODULE_PATH}` },
      },
      {
        id: 'journeys-home-path',
        name: tn('BREADCRUMB.journeys'),
        to: { path: `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.JOURNEYS_PATH}` },
      },
      {
        id: 'action-rules-path',
        name: action ? action.name : '',
      },
    ];

    const breadcrumbLinks = [
      {
        id: 'campaign-rules-id-path',
        name: action ? action.campaignName : '',
        to: {
          path: campaignLastPath.action,
        },
      },
      {
        id: 'action-rules-id-path',
        name: action ? action.name : '',
      },
    ];
    return isAutomation ? breadcrumbLinksAutomation : breadcrumbLinks;
  }, [action, campaignLastPath.action, isAutomation, tn]);
  /* cspell:enable*/

  const isActivateButtonDisabled = useMemo(() => {
    return (
      !isTriggerFormComplete || !actionNodeInfo.complete || !actionInfoEmail?.emailInfo.subject
    );
  }, [isTriggerFormComplete, actionNodeInfo.complete, actionInfoEmail?.emailInfo.subject]);

  const headerRulesActionButtons = useMemo(
    () => (
      <div className="flex gap-3">
        <If condition={!ruleEmailPath || (isAutomation && ruleEmailPath)}>
          <If condition={!isRuleActive}>
            <Button outline id="header-action__save" size="small" onClick={handleFinishRule}>
              {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.finish`)}
            </Button>
          </If>

          <If condition={isRuleActive}>
            <Button
              primary
              id="header-action__activate"
              isLoading={processingAction}
              size="small"
              onClick={handleCallbackPause}
            >
              {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.pause`)}
            </Button>
          </If>

          <If condition={!isRuleActive}>
            <Button
              primary
              disabled={isActivateButtonDisabled}
              id="header-action__activate"
              isLoading={processingAction}
              size="small"
              onClick={() => setToggleActivateRuleModal(true)}
            >
              {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.activate`)}
            </Button>
          </If>
        </If>

        <If condition={ruleEmailPath && !isRuleActive && !isAutomation}>
          <Button
            primary
            id="header-action__activate"
            isLoading={processingAction}
            size="small"
            onClick={handleCallbackContinue}
          >
            {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.continue`)}
          </Button>
        </If>
      </div>
    ),
    [
      ruleEmailPath,
      isRuleActive,
      handleFinishRule,
      t,
      processingAction,
      handleCallbackPause,
      isActivateButtonDisabled,
      isAutomation,
      handleCallbackContinue,
      setToggleActivateRuleModal,
    ]
  );

  const handleBackHeader = useCallback(() => {
    if (ruleEmailPath && !isRuleActive) {
      setToggleBackToRulesModal(true);
      return;
    }

    const locationPath = ruleEmailPath
      ? location.pathname.split('/').slice(0, -1).join('/')
      : campaignLastPath.action;

    navigate(locationPath);
  }, [ruleEmailPath, isRuleActive, location.pathname, campaignLastPath.action, navigate]);

  return (
    <div className="relative z-[1]">
      <Breadcrumb
        isEditable
        withoutMargin
        actionButtons={headerRulesActionButtons}
        customPaths={headerRulesPaths}
        handleOnBackClick={handleBackHeader}
        itemId={action?.actionId}
        onEditableClick={handleOpenModal}
      />
      <ModalSingleInput
        disabledUntilOnChange
        id="edit-action-table"
        isLoading={loading}
        isOpen={toggleEditActionsModal}
        rules={VALIDATION_RULES}
        title={t(`${TRANSLATE_RULES_RENAME}.rename`)}
        value={action?.name}
        onClose={handleCloseModal}
        onSubmit={handleApplyRename}
        onValidateInputFetch={handleValidation}
      />
      <ActivateAction loading={isLoadingRuleData} onConfirm={handleCallbackActivate} />

      <BackToRulesModal isOpen={toggleBackToRulesModal} onClose={setToggleBackToRulesModal} />
    </div>
  );
};

export default memo(HeaderSectionRules);
