import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Card, IconSvg, Text } from '@/lib/v2/components';
import { OpenNewWindow } from '@/lib/v2/icons/outline';
import { Table } from '@/lib/v3/components';

import { TRANSLATE_INSIGHTS_ACTIONS_SUMMARY } from '@/src/modules/InsightsModule/constants';
import { LAST_ACTION_QUANTITY } from '@/src/presentation/components/LastActionsReport/constants';

import { useActionsSummaryColumns } from './hooks/useActionsSummaryColumns';

import { atomActionsList } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { ChannelInfoByID } from '@/modules/CampaignsModule/components/CampaignActions/ActionsTable/hooks/useActionsTableColumns';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import {
  EActionTypes,
  ICampaignActionsListPayload,
  ITableCampaignActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';

const payload: ICampaignActionsListPayload = {
  page: 1,
  limit: LAST_ACTION_QUANTITY,
  sortField: 'exeDate',
  sortOrder: 'desc',
  type: EActionTypes.FINISHED,
};

const ActionsSummary = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['insights']);

  const [actionsList, setActionsList] = useAtom(atomActionsList);

  const { getList, loading } = useCampaignActions();
  const { columns } = useActionsSummaryColumns();

  useEffect(() => {
    void (async () => await getList(payload))();

    return () => {
      setActionsList(RESET);
    };
  }, [getList, setActionsList]);

  const handleRowClick = useCallback(
    (row: ITableCampaignActions) => {
      const { channel } = row.action;
      const { route } = ChannelInfoByID[channel as keyof typeof ChannelInfoByID];

      navigate(
        `${CAMPAIGNS_PATH}/${row.action.campaignId}/${route}/${row.action.actionId}/summary`
      );
    },
    [navigate]
  );

  return (
    <Card noShadow className="flex w-full justify-between pb-0" isFullHeight={false}>
      <div className="mb-4 mt-1 flex w-full items-center justify-between px-4">
        <Text color="black" fontWeight="normal" variant="headline">
          {t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.title`)}
        </Text>

        <Link
          className="flex flex-row items-center gap-2 text-12 text-emblue-primary"
          to={'/v2/campaigns'}
        >
          {t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.goToCampaigns`)}
          <IconSvg svgComponent={<OpenNewWindow />} />
        </Link>
      </div>

      <Table
        columns={columns}
        data={actionsList || []}
        emptyState={{
          hasFiltersApplied: false,
          noDataResultComponent: (
            <div className="py-14">
              <Text alignment="center" color="gray" variant="text">
                {t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.emptyActions`)}
              </Text>
            </div>
          ),
          noFilterResultComponent: '',
        }}
        isLoading={loading}
        onRowClick={handleRowClick}
      />
    </Card>
  );
};

export default memo(ActionsSummary);
