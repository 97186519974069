import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, If, Tab, TabContent, TabItem, TabNav, TabPane } from '@/lib/v2/components';

import {
  GRAPH_EVOLUTION_MONTHS,
  TRANSLATE_INSIGHTS_CONTACTS,
} from '@/src/modules/InsightsModule/constants';

import { BarGraphContacts } from './components/BarGraphContacts';
import { BarGraphSkeleton } from './components/BarGraphSkeleton';
import { ContactsGraphLegend } from './components/ContactsGraphLegend';
import { useGraphEvolution } from './hooks/useGraphEvolution';

const ContactsGraph = () => {
  const { t } = useTranslation(['insights']);
  const [currentTab, setCurrentTab] = useState<number>(3);

  const {
    state: { loading },
    events: { getGraphEvolutionMonths },
  } = useGraphEvolution();

  useEffect(() => {
    void (async () => await getGraphEvolutionMonths(GRAPH_EVOLUTION_MONTHS.THREE))();
  }, []);

  const handleTabChange = useCallback(
    async (tab: number) => {
      setCurrentTab(tab);
      await getGraphEvolutionMonths(tab);
    },
    [setCurrentTab, getGraphEvolutionMonths]
  );

  return (
    <Card noShadow className="relative flex w-full justify-between pb-0">
      <ContactsGraphLegend />
      <Tab currentTab={currentTab}>
        <TabNav className="flex justify-end" gap="small">
          <TabItem tabId={3} onClick={() => handleTabChange(3)}>
            {t(`${TRANSLATE_INSIGHTS_CONTACTS}.rangeMonths`, {
              months: 3,
            })}
          </TabItem>
          <TabItem tabId={6} onClick={() => handleTabChange(6)}>
            {t(`${TRANSLATE_INSIGHTS_CONTACTS}.rangeMonths`, {
              months: 6,
            })}
          </TabItem>
          <TabItem tabId={12} onClick={() => handleTabChange(12)}>
            {t(`${TRANSLATE_INSIGHTS_CONTACTS}.rangeMonths`, {
              months: 12,
            })}
          </TabItem>
        </TabNav>
        <TabContent>
          <TabPane tabId={3}>
            <If condition={!loading} fallback={<BarGraphSkeleton />}>
              <BarGraphContacts />
            </If>
          </TabPane>
          <TabPane tabId={6}>
            <If condition={!loading} fallback={<BarGraphSkeleton />}>
              <BarGraphContacts />
            </If>
          </TabPane>
          <TabPane tabId={12}>
            <If condition={!loading} fallback={<BarGraphSkeleton />}>
              <BarGraphContacts />
            </If>
          </TabPane>
        </TabContent>
      </Tab>
    </Card>
  );
};

export default memo(ContactsGraph);
