import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { atomActionData } from '@/src/modules/CampaignsModule/atoms/campaignAtom';

export const useActionData = (actionId: number) => {
  const [loading, setLoading] = useState(false);
  const [actionData, setActionData] = useAtom(atomActionData);
  const campaignService = useCampaignsService();

  const getCampaignData = useCallback(async () => {
    if (!actionId) return;

    setLoading(true);
    const { action } = await campaignService.getActionByID(actionId);

    if (action) {
      setActionData((prev) => ({ ...prev, ...action }));
      setLoading(false);
    }
  }, [actionId, campaignService, setActionData]);

  const refetch = useCallback(() => {
    void getCampaignData();
  }, [getCampaignData]);

  useEffect(() => {
    if (actionData === null || (actionData?.actionId ? actionData.actionId !== actionId : false))
      void getCampaignData();
  }, [actionData, actionId, getCampaignData]);

  return {
    action: actionData,
    refetch,
    loading,
  };
};
