import { useAtomValue, useSetAtom } from 'jotai';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Checkbox, Input, TimeInput } from '@/lib/v2/components';
import DateInput, { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { eventOnChangeTimeInput } from '@/lib/v2/components/TimeInput/TimeInput';

import { useUserData } from '@/src/application/hooks/useUserData';
import DateConverter from '@/src/application/util/DateConverter';

import { atomProcessingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomCallbackStep,
  atomDeliveryInformation,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import useSchedulingForm, {
  SchedulingFormState,
} from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailScheduling/hooks/useSchedulingForm';
import { SubjectButtons } from '@/modules/CampaignsModule/components/SubjectButtons';
import { ISubjectChangeButtonsProps } from '@/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { NEXT_YEAR_OFFSET } from '@/modules/CampaignsModule/constants';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import {
  IDeliveryActionResponse,
  ISetDeliveryActionPayload,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  EActionStatusAvailable,
  EChannelsID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IDeliveryFormProps {
  disabled?: boolean;
}

const SchedulingDeliveryForm = ({ disabled = false }: IDeliveryFormProps) => {
  const { t } = useTranslation();
  const { campaignId, actionId } = useParams();
  const navigate = useNavigate();

  const deliveryInformation = useAtomValue(atomDeliveryInformation);
  const setCallbackStep = useSetAtom(atomCallbackStep);
  const setProcessingAction = useSetAtom(atomProcessingAction);

  const [userData] = useUserData();
  const { control, RULES, handleSubmit, setValue, getValues, resetField, clearErrors } =
    useSchedulingForm();
  const { saveDeliveryAction, setActionState } = useActionEmailData(Number(actionId));

  const [scheduledDeliveryDate, setScheduledDeliveryDate] = useState<Date | undefined>(undefined);
  const [scheduledDeliveryTime, setScheduledDeliveryTime] = useState<Date | undefined>(undefined);
  const [scheduledDeliveryMinDateTime, setScheduledDeliveryMinDateTime] = useState<
    Date | undefined
  >(undefined);
  const [reinforcementDateState, setReinforcementDateState] = useState<Date | undefined>(undefined);
  const [reinforcementTimeState, setReinforcementTimeState] = useState<Date | undefined>(undefined);
  const [reinforcementMinDateTime, setReinforcementMinDateTime] = useState<Date | undefined>(
    undefined
  );
  const [reinforcementMinDate, setReinforcementMinDate] = useState<Date | undefined>(undefined);
  const [reinforcementCheckbox, setReinforcementCheckbox] = useState<boolean>(
    ((deliveryInformation?.reinforcement?.isReinforcementShipment &&
      deliveryInformation?.shippingDate) as boolean) || false
  );

  const formatGMT = useMemo(
    () => (userData.timeOffset ? `GMT${userData.timeOffset}` : undefined),
    [userData.timeOffset]
  );

  const currentDateRoot = useMemo(
    () => (formatGMT ? DateConverter.toGMTDate(new Date().toISOString(), formatGMT) : undefined),
    [formatGMT]
  );

  const maxDateScheduledDelivery = useMemo(() => {
    if (!formatGMT) return undefined;
    const date = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
    date.setFullYear(date.getFullYear() + NEXT_YEAR_OFFSET);
    return DateConverter.toGMTDate(date.toISOString(), formatGMT);
  }, [formatGMT]);

  useEffect(() => {
    const {
      shippingDate,
      reinforcement: { subject, startDate: reinforcementStartDate },
    } = deliveryInformation as IDeliveryActionResponse;

    if (!formatGMT) return;

    const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);

    if (shippingDate && !scheduledDeliveryDate) {
      const newShippingDate = DateConverter.toGMTDate(shippingDate, formatGMT);

      setValue('scheduledDeliveryDate', newShippingDate);
      setScheduledDeliveryDate(newShippingDate);

      setValue('scheduledDeliveryTime', newShippingDate);
      setScheduledDeliveryTime(newShippingDate);

      if (newShippingDate.getDate() === currentDate.getDate()) {
        if (newShippingDate.getHours() > currentDate.getHours()) {
          setScheduledDeliveryMinDateTime(currentDate);
        } else {
          setScheduledDeliveryMinDateTime(newShippingDate);
        }
      } else {
        currentDate.setHours(0, 0, 0, 0);
        setScheduledDeliveryMinDateTime(currentDate);
      }

      if (reinforcementStartDate) {
        const newReinforcementDate = DateConverter.toGMTDate(reinforcementStartDate, formatGMT);
        setValue('reinforcementCheckbox', true);
        setValue('reinforcementSubject', subject || '');
        setValue('reinforcementDate', newReinforcementDate);
        setValue('reinforcementTime', newReinforcementDate);
        setReinforcementDateState(newReinforcementDate);
        setReinforcementTimeState(newReinforcementDate);

        const minDate = new Date(newShippingDate);
        minDate.setDate(minDate.getDate() + 1);
        minDate.setHours(0, 0, 0, 0);
        setReinforcementMinDate(minDate);

        if (
          newReinforcementDate.getDate() === currentDate.getDate() &&
          newReinforcementDate.getMonth() === currentDate.getMonth() &&
          newReinforcementDate.getFullYear() === currentDate.getFullYear()
        ) {
          setReinforcementMinDateTime(currentDate);
        } else if (
          newReinforcementDate.getDate() === minDate.getDate() &&
          newReinforcementDate.getMonth() === minDate.getMonth() &&
          newReinforcementDate.getFullYear() === minDate.getFullYear()
        ) {
          setReinforcementMinDateTime(newShippingDate);
        } else {
          const minDateTime = new Date(newReinforcementDate);
          minDateTime.setHours(0, 0, 0, 0);
          setReinforcementMinDateTime(minDateTime);
        }
      }
    }
  }, [formatGMT, setValue]);

  const onChangeScheduledDeliveryDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (!formatGMT) return;
      const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);
      const inputDate = event as Date;

      if (
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear()
      ) {
        setScheduledDeliveryMinDateTime(currentDate);
      } else {
        const minDateTime = new Date(inputDate);
        minDateTime.setHours(0, 0, 0, 0);
        setScheduledDeliveryMinDateTime(minDateTime);
      }

      const nextDay = new Date(inputDate);
      nextDay.setDate(nextDay.getDate() + 1);
      nextDay.setHours(0, 0, 0, 0);
      setReinforcementMinDate(nextDay);

      setScheduledDeliveryDate(inputDate);
      setScheduledDeliveryTime(undefined);

      resetField('scheduledDeliveryTime');
      resetField('reinforcementDate');
      resetField('reinforcementTime');
      setReinforcementDateState(undefined);
      setReinforcementTimeState(undefined);
    },
    [formatGMT, resetField]
  );

  const onChangeScheduledDeliveryTime: eventOnChangeTimeInput = useCallback(
    (event) => {
      if (!formatGMT) return;

      const inputTime = event as Date;

      setScheduledDeliveryTime(inputTime);
      resetField('reinforcementDate');
      resetField('reinforcementTime');
      setReinforcementDateState(undefined);
      setReinforcementTimeState(undefined);

      const inputDate = event as Date;
      setReinforcementMinDateTime(inputDate);
    },
    [resetField, formatGMT]
  );

  const onChangeReinforcementDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (!formatGMT) return;
      const inputDate = event as Date;
      const currentDate = DateConverter.toGMTDate(new Date().toISOString(), formatGMT);

      setReinforcementDateState(inputDate);

      const scheduledDate = scheduledDeliveryDate as Date;
      const nextDay = new Date(scheduledDate);
      nextDay.setDate(nextDay.getDate() + 1);

      if (
        inputDate.getDate() === nextDay.getDate() &&
        inputDate.getMonth() === nextDay.getMonth() &&
        inputDate.getFullYear() === nextDay.getFullYear()
      ) {
        setReinforcementMinDateTime(scheduledDeliveryTime);
      } else if (
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear()
      ) {
        setReinforcementMinDateTime(currentDate);
      } else {
        const minDateTime = new Date(inputDate);
        minDateTime.setHours(0, 0, 0, 0);
        setReinforcementMinDateTime(minDateTime);
      }

      setReinforcementTimeState(undefined);
      resetField('reinforcementTime');
    },
    [formatGMT, resetField, scheduledDeliveryDate, scheduledDeliveryTime]
  );

  const onChangeReinforcementTime: eventOnChangeTimeInput = useCallback((event) => {
    setReinforcementTimeState(event as Date);
  }, []);

  const handleChangeReinforcement = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setReinforcementCheckbox(checked);

      if (checked && scheduledDeliveryDate) {
        const nextDay = new Date(scheduledDeliveryDate);
        nextDay.setDate(nextDay.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);
        setReinforcementMinDate(nextDay);
      }

      if (!checked) {
        clearErrors();
        resetField('reinforcementDate');
        resetField('reinforcementTime');
        setReinforcementDateState(undefined);
        setReinforcementTimeState(undefined);
        setReinforcementMinDate(undefined);
      }
    },
    [clearErrors, resetField, scheduledDeliveryDate]
  );

  const getGMTDate = (date: Date, time: Date) => {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');

    const hour = String(time?.getHours()).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');
    const minutes = String(time?.getMinutes()).padStart(2, '0');

    return {
      date: `${year}-${month}-${day}`,
      hour: `${hour}:${minutes}`,
    };
  };

  const handleSubmitData = useCallback(
    async (formData: SchedulingFormState) => {
      setProcessingAction(true);

      const {
        scheduledDeliveryDate,
        scheduledDeliveryTime,
        reinforcementSubject,
        reinforcementCheckbox,
        reinforcementDate,
        reinforcementTime,
      } = formData;

      if (!scheduledDeliveryDate || !scheduledDeliveryTime) return;

      const delivery = getGMTDate(scheduledDeliveryDate, scheduledDeliveryTime);
      const deliveryReinforcement =
        reinforcementDate && reinforcementTime
          ? getGMTDate(reinforcementDate, reinforcementTime)
          : null;

      const payload: ISetDeliveryActionPayload = {
        actionType: EChannelsID.EMAIL,
        actionId: Number(actionId),
        isImmediateShipment: false,
        delivery: delivery,
        reinforcement: {
          subject: reinforcementCheckbox ? reinforcementSubject : null,
          isReinforcementShipment: reinforcementCheckbox ?? false,
          date: deliveryReinforcement ? deliveryReinforcement?.date : null,
          hour: deliveryReinforcement ? deliveryReinforcement.hour : null,
        },
      };

      await saveDeliveryAction(payload);
      await setActionState({
        actionId: Number(actionId),
        statusId: EActionStatusAvailable.CONFIRMABLE_DRAFT,
      });

      setProcessingAction(false);
      navigate(`/v2/campaigns/${Number(campaignId)}/email/${Number(actionId)}/summary`);
    },
    [actionId, campaignId, navigate, saveDeliveryAction, setActionState, setProcessingAction]
  );

  useEffect(() => {
    setCallbackStep(() => handleSubmit(handleSubmitData));
    return () => setProcessingAction(false);
  }, []);

  const handleChangeSubjectButton = ({ item }: ISubjectChangeButtonsProps) => {
    const currentSubject = getValues('reinforcementSubject');
    setValue('reinforcementSubject', `${currentSubject} ${item}`);
  };

  return (
    <form
      id="scheduling-form"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        handleSubmit(handleSubmitData);
      }}
    >
      <div className="mb-5 flex gap-3">
        <div className="flex w-200">
          <DateInput
            fullWidth
            isRequired
            control={control}
            date={scheduledDeliveryDate}
            disabled={disabled}
            id="scheduledDeliveryDate"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.date')}
            maxDate={maxDateScheduledDelivery}
            minDate={currentDateRoot}
            name="scheduledDeliveryDate"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectDate')}
            rules={RULES.scheduledDeliveryDate}
            onChange={onChangeScheduledDeliveryDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            isRequired
            control={control}
            date={scheduledDeliveryTime}
            disabled={disabled}
            id="scheduledDeliveryTime"
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.time')}
            minTime={scheduledDeliveryMinDateTime || currentDateRoot}
            name="scheduledDeliveryTime"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectTime')}
            rules={RULES.scheduledDeliveryTime}
            onChange={onChangeScheduledDeliveryTime}
          />
        </div>
      </div>
      <div className="mb-5 flex items-center gap-3" id="scheduling-checkbox-container">
        <Checkbox
          checked={reinforcementCheckbox}
          color="#D3DDEA"
          control={control}
          disabled={disabled}
          id="reinforcementCheckbox"
          label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.reinforcement')}
          name="reinforcementCheckbox"
          size={16}
          onChange={handleChangeReinforcement}
        />
      </div>
      <div className="mb-4 flex w-3/4 gap-2" id="scheduling-subject-container">
        <Input
          control={control}
          disabled={!reinforcementCheckbox || disabled}
          id="reinforcementSubject"
          isRequired={reinforcementCheckbox}
          name="reinforcementSubject"
          placeHolder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.subjectPlaceholder`)}
          rules={RULES.reinforcementSubject}
        />
        <div className="mt-1">
          <SubjectButtons
            actionId={Number(actionId)}
            disabled={!reinforcementCheckbox || disabled}
            id="email-scheduling-buttons"
            searchPlaceholder={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.searchDropdown`)}
            onChangeButtons={handleChangeSubjectButton}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <div className="flex w-200">
          <DateInput
            control={control}
            date={reinforcementDateState}
            disabled={
              !reinforcementCheckbox ||
              disabled ||
              !(scheduledDeliveryDate && scheduledDeliveryTime)
            }
            id="reinforcementDate"
            isRequired={reinforcementCheckbox}
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.date')}
            maxDate={maxDateScheduledDelivery}
            minDate={reinforcementMinDate}
            name="reinforcementDate"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectDate')}
            rules={RULES.reinforcementDate}
            onChange={onChangeReinforcementDate}
          />
        </div>
        <div className="flex w-[180px]">
          <TimeInput
            control={control}
            date={reinforcementTimeState}
            disabled={
              !reinforcementCheckbox ||
              disabled ||
              !(scheduledDeliveryDate && scheduledDeliveryTime)
            }
            id="reinforcementTime"
            isRequired={reinforcementCheckbox}
            label={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.time')}
            minTime={reinforcementMinDateTime}
            name="reinforcementTime"
            placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.DELIVERY.selectTime')}
            rules={RULES.reinforcementTime}
            onChange={onChangeReinforcementTime}
          />
        </div>
      </div>
    </form>
  );
};

export default SchedulingDeliveryForm;
