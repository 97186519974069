import { useTranslation } from 'react-i18next';

import { Button, Flex, IconSvg } from '@/lib/v2/components';
import { CreateIcon } from '@/lib/v2/icons/outline';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { TRANSLATE_WHATSAPP_ACTION_FORM } from '@/src/modules/CampaignsModule/constants';

interface TemplateOptionsProps {
  disabled: boolean;
  isTrackingEnabled: boolean;
  onSelectTemplate: () => void;
  onCreateTemplate: () => void;
}

const TemplateOptions = ({
  disabled,
  isTrackingEnabled,
  onSelectTemplate,
  onCreateTemplate,
}: TemplateOptionsProps) => {
  const { t } = useTranslation('inbox');
  const enableWhatsAppTemplateCreation = useFeatureFlag('enableWhatsAppTemplateCreation');

  return (
    <Flex withGap alignment="center" gapSize="medium" itemAlignment="center">
      <Button
        outline
        secondary
        disabled={disabled || isTrackingEnabled}
        id="select-template-button"
        size="small"
        onClick={onSelectTemplate}
      >
        {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.selectTemplateButtonText`)}
      </Button>
      <Button
        outline
        secondary
        disabled={disabled || isTrackingEnabled || !enableWhatsAppTemplateCreation}
        iconLeft={<IconSvg strokeColor="gray" svgComponent={<CreateIcon />} />}
        id="create-template-button"
        size="small"
        onClick={onCreateTemplate}
      >
        {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.createTemplateButtonText`)}
      </Button>
    </Flex>
  );
};

export default TemplateOptions;
