import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ModalConfirmation } from '@/lib/v2/organisms';

import { AUTOMATION_PATHS } from '@/modules/AutomationModuleNew/constants';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { TRANSLATE_RULES_MODAL_BACK_TO_RULES } from '@/modules/RulesModule/constants';

interface BackToCampaignsModalProps {
  onClose: (value: boolean) => void;
  isOpen: boolean;
}

const BackToRulesModal = ({ onClose, isOpen }: BackToCampaignsModalProps) => {
  const { t } = useTranslation('rules');
  const navigate = useNavigate();
  const { campaignId, actionId, actionType } = useParams();
  const { pathname } = useLocation();
  const isAutomation = pathname.includes('/v2/automation');

  const handleOnConfirmModal = useCallback(() => {
    onClose(!isOpen);

    if (isAutomation) {
      const backUrl = `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.JOURNEYS_PATH}`;
      navigate(backUrl);
      return;
    }

    navigate(`${CAMPAIGNS_PATH}/${String(campaignId)}/${String(actionType)}/${String(actionId)}`);
  }, [actionId, actionType, campaignId, isAutomation, isOpen, navigate, onClose]);

  return (
    <ModalConfirmation
      description={t(`${TRANSLATE_RULES_MODAL_BACK_TO_RULES}.description`)}
      isOpen={isOpen}
      textCancelButton={t(`${TRANSLATE_RULES_MODAL_BACK_TO_RULES}.cancel`)}
      textConfirmButton={t(`${TRANSLATE_RULES_MODAL_BACK_TO_RULES}.confirm`)}
      title={t(`${TRANSLATE_RULES_MODAL_BACK_TO_RULES}.title`)}
      onClose={onClose}
      onConfirmButton={handleOnConfirmModal}
    />
  );
};

export default memo(BackToRulesModal);
