// cSpell:words requerido
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';

import { atomFormFieldsStatusAtom, atomRuleData } from '@/modules/RulesModule/atoms/rules';
import {
  CustomFieldsDTO,
  IFormProps,
  IIncomingField,
  IRuleForm,
} from '@/modules/RulesModule/interfaces';

const useFieldsStatus = (formData: IIncomingField[], form: IFormProps<IRuleForm>) => {
  const rulesData = useAtomValue(atomRuleData);
  const [formFieldsStatus, setFormFieldsStatus] = useAtom(atomFormFieldsStatusAtom);

  const initialFormFieldsStatus = useMemo(() => {
    if (formFieldsStatus.length > 0) {
      return formFieldsStatus;
    }

    return formData.map((field) => {
      const customField = rulesData?.customFieldsDTO?.find((custom) => custom.id === field.id);
      return {
        id: field.id,
        switchStatus: Boolean(customField),
        requiredStatus: customField ? customField.requerido : false,
      };
    });
  }, [formFieldsStatus, formData, rulesData]);

  useEffect(() => {
    if (formFieldsStatus.length === 0 && initialFormFieldsStatus.length > 0) {
      setFormFieldsStatus(initialFormFieldsStatus);
    }
  }, [formFieldsStatus.length, initialFormFieldsStatus, setFormFieldsStatus]);

  useEffect(() => {
    return () => {
      setFormFieldsStatus(formFieldsStatus);
    };
  }, []);

  const handleChange = useCallback(
    (id: number, isCheckbox: boolean) => {
      setFormFieldsStatus((prevState) => {
        const newState = prevState.map((status) => {
          if (status.id === id) {
            if (isCheckbox) {
              const newRequiredStatus = !status.requiredStatus;
              return {
                ...status,
                requiredStatus: newRequiredStatus,
                switchStatus: newRequiredStatus || status.switchStatus,
              };
            } else {
              const newSwitchStatus = !status.switchStatus;
              return {
                ...status,
                switchStatus: newSwitchStatus,
                requiredStatus: newSwitchStatus ? status.requiredStatus : false,
              };
            }
          }
          return status;
        });

        const updatedCustomFieldsDTO: CustomFieldsDTO[] = newState
          .filter(({ switchStatus }) => switchStatus)
          .map(({ id, requiredStatus }) => ({
            id,
            requerido: requiredStatus,
          }));

        form.setValue('customFieldsDTO', updatedCustomFieldsDTO);

        return newState;
      });
    },
    [setFormFieldsStatus, form]
  );

  return {
    formFieldsStatus,
    handleChange,
  };
};

export default useFieldsStatus;
