import { format } from 'date-fns';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { useUserData } from '@/src/infrastructure/services/useUserData';

import { ELanguageMap } from '@/modules/CampaignsModule/utils/formattedNumber';
import { atomGraphEvolutionList } from '@/modules/InsightsModule/atoms/InsightsAtom';
import { GRAPH_EVOLUTION_MONTHS } from '@/modules/InsightsModule/constants';
import { useDataInsights } from '@/modules/InsightsModule/hooks/useDataInsights';
import { IGraphEvolutionMonths } from '@/modules/InsightsModule/interfaces/ContactsEvolution';

export const useGraphEvolution = () => {
  const setAtomGraphEvolutionList = useSetAtom(atomGraphEvolutionList);
  const [userData] = useUserData();
  const {
    state: { loading },
    events: { getContactsGraphData },
  } = useDataInsights();

  const formatMonthYear = useCallback(
    (month: number, year: number) => {
      const date = new Date(year, month - 1);
      const userLanguageCast =
        ELanguageMap[userData.language as keyof typeof ELanguageMap] ?? ELanguageMap.en;
      const monthStr = date.toLocaleDateString(userLanguageCast, { month: 'short' });
      return `${monthStr.charAt(0).toUpperCase()}${monthStr.slice(1)}`;
    },
    [userData.language]
  );

  const castDataGraphEvolution = (data: IGraphEvolutionMonths[]) => {
    if (!data) return [];
    return data.map((item) => ({
      name: formatMonthYear(item.month, item.year),
      contacts: item.maxContacts,
      contacted: item.totalSent,
    }));
  };

  const getGraphEvolutionMonths = async (months: GRAPH_EVOLUTION_MONTHS) => {
    const today = new Date();
    const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const pastDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - months + 1, 1);

    const response = await getContactsGraphData({
      startDate: format(pastDate, 'yyyy-MM-dd'),
      endDate: format(today, 'yyyy-MM-dd'),
    });

    const dataGraphEvolution = castDataGraphEvolution(response as IGraphEvolutionMonths[]);
    setAtomGraphEvolutionList(dataGraphEvolution);
  };

  return {
    state: {
      loading,
    },
    events: {
      getGraphEvolutionMonths,
    },
  };
};
