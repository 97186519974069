import { datadogRum } from '@datadog/browser-rum';
import { FlagProvider, useUnleashContext } from '@unleash/proxy-client-react';
import { useAtom } from 'jotai';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Zendesk } from '@/lib/components/Zendesk';
import { If, Spinner, ToastContainer } from '@/lib/v2/components';

import UserProvider from './application/context/UserContext';
import { useUserData } from './application/hooks/useUserData';
import { useVersion } from './application/hooks/useVersion';
import { Environments, getEnv } from './application/hooks/util/useEnv';
import { useFeatureFlag } from './application/hooks/util/useFeatureFlag';
import { IdentifyUser } from './application/util/mixPanel';
import { atomFreeTrial } from './atoms/FreeTrial';
import { parseJwt, readCookie, setCookie } from './compat/util';
import { FileProvider } from './ContactsModule/contexts/ImportFileContext';
import { ServiceMethods } from './infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from './infrastructure/Protocol/useEmblue';
import { LoginLayout } from './modules/AuthModule';
import { AUTH_PATHS, LOGIN_LS } from './modules/AuthModule/constants';
import MultiFactorAuthentication from './modules/AuthModule/screens/MultiFactorAuthentication';
import BackofficeModuleRouter from './modules/BackofficeModule/router/BackofficeModuleRouter';
import { BlockAndSuspendModal } from './presentation/components/BlockAndSuspendModal';
import DownloadContacts from './presentation/components/DownloadContacts';
import { DashboardContainer } from './presentation/screens/DashBoard';
import MainContainer from './presentation/screens/MainContainer';
import { IContractPaymentInfo } from './presentation/types/interfaces/IContractPaymentInfo';
import { closeDropDown } from './presentation/util/constants/closeDropDown';
import AppRouterVersionTwo from './routers/AppRouterVersionTwo';
import { convertValuesToString } from './utils/Objects';
import configData from './config.json';
import Translations from './Translations';

import './index.css';

import OnsiteScreen from '@/modules/OnSiteModule/screens/OnsiteScreen/OnsiteScreen';

export const EmblueLegacyContext = createContext({
  contacts: false,
  setContext: (() => { }) as Function,
});

const onsiteEnabled = (window as any).__onsiteEnabled || false;

const AppInstance = () => {
  const env = getEnv() as Exclude<Environments, 'storybook'>;
  const { JWT_DEV_TOKEN } = configData.endpoints[env];
  const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : (window as any).__JWTToken;
  const [userData] = useUserData();

  const updateContext = useUnleashContext();
  const { contacts } = useContext(EmblueLegacyContext);
  const { version } = useVersion();
  const loginV2CheckCountryIsEnabled = useFeatureFlag('loginV2CheckCountry');
  const hideMainContainerV1 = useFeatureFlag('hideMainContainerV1');
  const MFAIsEnabled = useFeatureFlag('MFA');
  const [paymentInfo, , paymentInfoIsLoading] = useEmblue(ServiceMethods.getPaymentInfo);
  const [freeTrial] = useEmblue(ServiceMethods.getFreeTrialBlock);
  const [freeTrialBlockState, setFreeTrialBlockState] = useAtom(atomFreeTrial);
  const contactsEnabled = useMemo(() => !contacts, [contacts]);

  const service = useService();

  let attemptCount = 0;
  const maxAttempts = 10;

  const logoutHandler = () => {
    const intervalId = setInterval(() => {
      void service?.logout();

      attemptCount++;
      if (attemptCount >= maxAttempts) {
        clearInterval(intervalId);
      }
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loginV2CheckCountry = async (email: string, accountId: any, contractId: any) => {
    const userMatchWithCountry = await service?.loginV2CheckCountry();
    if (!userMatchWithCountry) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      datadogRum.addAction('userDoesNotMatchWithCountry', { email, accountId, contractId });

      logoutHandler();
    }
  };

  useEffect(() => {
    if (jwtToken) {
      const user = parseJwt(jwtToken);
      (window as any).__emblueMetadata = user;
      (window as any).emblueMetadata = user;
      void updateContext({
        userId: user.contractId,
        properties: {
          userEmail: user.email,
          accountId: user.accountId,
          contractId: user.contractId,
        },
      });

      if (loginV2CheckCountryIsEnabled) {
        void loginV2CheckCountry(user.email, user.accountId, user.contractId);
      }
    }
    if (freeTrial) {
      setFreeTrialBlockState(freeTrial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtToken, updateContext, freeTrial, userData.email, loginV2CheckCountryIsEnabled]);

  useEffect(() => {
    if (contactsEnabled) {
      document.body.classList.add('emblue-contacts-v2');
    } else {
      document.body.classList.remove('emblue-contacts-v2');
    }

    // eslint-disable-next-line
    window.addEventListener('message', ({ data }) => {
      if (data.event === configData.HOME.GO_TO_V2) {
        location.reload(); // Temporally to solve cache problems between v1 and v2
      } else if (data.payload?.event === configData.HOME.CREATE_SENDER_MODAL) {
        window.location.href = '/v2/configuration/senders';
      }
    });
  }, [contactsEnabled]);

  const mfaRequiredCookieFake = readCookie('mfaCookie');
  // const skippedMfaCookie = readCookie('skippedMfa');

  useEffect(() => {
    const contactsCant = Number(localStorage.getItem('totalContacts') ?? 0);
    const blockEndpointByContacts =
      contactsCant === 0 || contactsCant >= configData.maxContactsToShowStatusCards;
    localStorage.setItem('showStatusCards', String(!blockEndpointByContacts));
  }, []);

  useEffect(() => {
    const loginLSItem = localStorage.getItem(LOGIN_LS);
    const sentTrack = async () => {
      const userDataStr = convertValuesToString(userData);
      const { name, ...restDataUser } = userDataStr;

      const response = await service.registerLogin({
        names: name as string,
        ...restDataUser,
      });

      return response;
    };

    if (loginLSItem) {
      void sentTrack();
      localStorage.removeItem(LOGIN_LS);
    }
  }, [service, userData]);

  if (MFAIsEnabled && !mfaRequiredCookieFake && !userData.isSSO) {
    return <Navigate to={`${AUTH_PATHS.LOGIN_PATH}/${AUTH_PATHS.MFA}`} />;
  }

  return (
    <div>
      <div
        aria-hidden
        className="_app__container"
        style={{ position: 'relative', zIndex: 999 }}
        onClick={closeDropDown}
      >
        <Spinner />
        <Zendesk />

        <FileProvider>
          <If condition={onsiteEnabled === false}>
            <If condition={version === 'v1' && !hideMainContainerV1}>
              <HashRouter>
                <Routes>
                  <Route
                    element={<MainContainer counter={freeTrialBlockState} userData={userData} />}
                    path="/"
                  />
                  <Route
                    element={
                      <DashboardContainer counter={freeTrialBlockState} userData={userData} />
                    }
                    path="/dashboard"
                  />
                </Routes>
              </HashRouter>
            </If>
            <If condition={version === 'v2'}>
              <UserProvider paymentInfo={paymentInfo} userData={userData}>
                <AppRouterVersionTwo contactsEnabled={contactsEnabled} />
              </UserProvider>
            </If>
          </If>
        </FileProvider>

        <DownloadContacts />
      </div>
      <If condition={paymentInfo !== undefined && !paymentInfoIsLoading}>
        <UserProvider paymentInfo={paymentInfo} userData={userData}>
          <BlockAndSuspendModal
            paymentInfo={paymentInfo as IContractPaymentInfo}
            userData={userData}
          />
        </UserProvider>
      </If>
    </div>
  );
};

const SecurityInstance = () => {
  return (
    <Routes>
      <Route element={<LoginLayout />} path={'/Login'}>
        <Route element={<MultiFactorAuthentication />} path={'/Login/mfa'} />
      </Route>
    </Routes>
  );
};

let rumInitialized = false;

export const App = () => {
  const env = getEnv() as Exclude<Environments, 'storybook'>;
  const [emblueLegacy, setEmblueLegacy] = useState({ contacts: false });
  const [userData] = useUserData();

  const config = {
    url:
      env === 'production'
        ? 'https://unleash.embluemail.com/proxy'
        : 'https://unleash-staging.embluemail.com/proxy',
    clientKey: 'unleash-proxy-prod',
    refreshInterval: 15,
    appName: 'v2-frontend',
    environment: env === 'production' ? 'production' : 'development',
  };

  useEffect(() => {
    IdentifyUser(userData);
    if (!rumInitialized) {
      rumInitialized = true;
      datadogRum.init({
        clientToken: 'pub58d3aa31599b679aaf3b81890699ea40',
        applicationId: '5789c698-ac9f-491e-a297-a261e2c131fe',
        site: 'datadoghq.com',
        service: 'ema',
        env: getEnv() === 'production' ? 'prod' : getEnv(),
        version: 'v2.0.0',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackFrustrations: true,
        trackInteractions: true,
        trackUserInteractions: true,
        allowedTracingUrls: [/^https:\/\/(?!frontapi|api)[a-zA-Z\d.-]+\.embluemail\.com\//],
      });
    }
    datadogRum.startSessionReplayRecording && datadogRum.startSessionReplayRecording();
    if (userData && userData.email) {
      datadogRum.setUser({ id: userData.email, ...userData });
      setCookie('user_data', JSON.stringify(userData), '/');
    }
  }, [userData]);

  return (
    <>
      <FlagProvider config={config}>
        <Translations>
          <EmblueLegacyContext.Provider value={{ ...emblueLegacy, setContext: setEmblueLegacy }}>
            <BrowserRouter>
              <Routes>
                {onsiteEnabled && <Route element={<OnsiteScreen />} path="/*" />}
                <Route element={<AppInstance />} path="/v2/*" />
                <Route element={<SecurityInstance />} path="/Account/*" />
                <Route element={<BackofficeModuleRouter />} path="/v2/backoffice/*" />
              </Routes>
            </BrowserRouter>
          </EmblueLegacyContext.Provider>
        </Translations>
      </FlagProvider>
      <ToastContainer />
    </>
  );
};
