import { useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { IconSvg } from '@/lib/v2/components';
import { DragAndDropIcon } from '@/lib/v2/icons/general';
import { InsightCardProps } from '@/lib/v2/organisms/InsightCard/InsightCard';

import { atomNewRulePath } from '@/src/modules/RulesModule/atoms/rules';

import { AUTOMATION_PATHS } from '@/modules/AutomationModule/constants';
import { BACK_URL_KEY } from '@/modules/AutomationModuleNew/constants';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';

const ACTIONS_CARDS = 'CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.CARD_LIST';

const useRulesEmail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { campaignId, actionId, actionType } = useParams();
  const isAutomation = pathname.includes('automation');

  const setNewRulePath = useSetAtom(atomNewRulePath);

  const EDITOR_ROUTE = useMemo(
    () => (route: string) => {
      if (campaignId && actionId && actionType) {
        return isAutomation
          ? `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${campaignId}/action/${actionId}/${actionType}/${route}`
          : `${CAMPAIGNS_PATH}/${campaignId}/${actionType}/${actionId}/${route}`;
      }
    },
    [campaignId, actionId, actionType, isAutomation]
  );

  const handleCardClick = useCallback(
    (route: string) => {
      const editorRoute = EDITOR_ROUTE(route);
      if (editorRoute) {
        if (isAutomation) sessionStorage.setItem(BACK_URL_KEY, window.location.pathname);
        setNewRulePath(true);
        navigate(editorRoute, {
          state: { from: 'rules' },
        });
      }
    },
    [EDITOR_ROUTE, setNewRulePath, navigate]
  );

  const cardList = useMemo<InsightCardProps[]>(
    () => [
      {
        icon: (
          <IconSvg
            height={'80px'}
            svgComponent={<DragAndDropIcon />}
            width={'80px'}
            withPadding="small"
          />
        ),
        description: t(`${ACTIONS_CARDS}.DRAG_AND_DROP.description`),
        title: t(`${ACTIONS_CARDS}.DRAG_AND_DROP.title`),
        isLoading: true,
        onClick: () => handleCardClick('editor'),
        id: 'drag-and-drop-card',
      },
      /* {
        icon: (
          <IconSvg
            height={'80px'}
            svgComponent={<HtmlEditorIcon />}
            width={'80px'}
            withPadding="small"
          />
        ),
        description: t(`${ACTIONS_CARDS}.HTML_EDITOR.description`),
        title: t(`${ACTIONS_CARDS}.HTML_EDITOR.title`),
        isLoading: true,
        onClick: () => handleCardClick('html-editor'),
        id: 'html-editor-card',
      }, */
    ],
    [t, handleCardClick]
  );

  return { cardList };
};

export default useRulesEmail;
