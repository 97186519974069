import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg, If, Text, Tooltip } from '@/lib/v2/components';
import {
  EmailActionIcon,
  EmailAPIIcon,
  EmailAttachmentIcon,
  EmailRemarketingIcon,
  EmailSplitIcon,
  EmailTriggerIcon,
  SmsActionIcon,
  SmsAPIIcon,
  WhatsAppIcon,
} from '@/lib/v2/icons/outline';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import { WHATSAPP_PREFIX } from '@/modules/CampaignsModule/constants';
import { useActionTableUtils } from '@/modules/CampaignsModule/hooks/useActionTableUtils';
import { useInboxUtils } from '@/modules/CampaignsModule/hooks/useInboxUtils';
import {
  EChannelsID,
  ITableCampaignActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { DateFormat, formatDate } from '@/modules/CampaignsModule/utils/formatDate';
import { ELanguageMap, formattedNumber } from '@/modules/CampaignsModule/utils/formattedNumber';
import {
  TRANSLATE_INSIGHTS_ACTIONS_SUMMARY,
  TRANSLATE_INSIGHTS_NS,
} from '@/modules/InsightsModule/constants';

const MAX_ACTION_NAME_LENGTH = 28;

const ICON_BY_ACTION_TYPE: Record<string, JSX.Element> = {
  email: <EmailActionIcon />,
  'email-remarketing': <EmailRemarketingIcon />,
  'email-split': <EmailSplitIcon />,
  'email-trigger': <EmailTriggerIcon />,
  'email-attachment': <EmailAttachmentIcon />,
  'mail-api': <EmailAPIIcon />,
  sms: <SmsActionIcon />,
  'sms-api': <SmsAPIIcon />,
  whatsapp: <WhatsAppIcon />,
};

export const useActionsSummaryColumns = () => {
  const { t, i18n } = useTranslation();

  const columnHelper = createColumnHelper<ITableCampaignActions>();

  const createSimpleEmail = useFeatureFlag('createSimpleEmail');

  const {
    events: { getRoutePathAction },
  } = useActionTableUtils();
  const {
    events: { isWhatsAppAction },
  } = useInboxUtils();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('action.name', {
        header: t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_HEADER.name`, {
          ns: TRANSLATE_INSIGHTS_NS,
        }) as unknown as string,
        cell: (info) => {
          const { name, channel, htmlOriginId, splitTest, originId } = info.row.original.action;

          const isWhatsApp = isWhatsAppAction(name);
          const actionName = isWhatsApp ? name.replace(WHATSAPP_PREFIX, '') : name;

          const router = getRoutePathAction({
            channel,
            htmlOriginId,
            isSplit: splitTest,
            originId,
            createSimpleEmail,
          });

          return (
            <div className="flex w-full flex-row items-center gap-4">
              <div className="shrink-0">
                <IconSvg
                  rounded
                  bgColor="frequent"
                  fillColor="frequent"
                  height="auto"
                  svgComponent={ICON_BY_ACTION_TYPE[isWhatsApp ? 'whatsapp' : router]}
                  width="40px"
                  withPadding="small"
                />
              </div>
              <div className="flex flex-col">
                <If
                  condition={actionName.length > MAX_ACTION_NAME_LENGTH}
                  fallback={
                    <Text fontWeight="medium" variant="text">
                      {actionName}
                    </Text>
                  }
                >
                  <Tooltip
                    isClickable
                    isDark
                    noArrow
                    alignment="start"
                    content={actionName}
                    position="top"
                  >
                    <Text className="max-w-[80%] truncate" fontWeight="medium" variant="text">
                      {actionName}
                    </Text>
                  </Tooltip>
                </If>
                <div className="emblue-text text-left font-normal">
                  {t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${isWhatsApp ? 'whatsapp' : router}`)}
                </div>
              </div>
            </div>
          );
        },
        meta: { widthClassName: 'w-[20%]' },
      }),
      columnHelper.accessor('action.campaignName', {
        header: t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_HEADER.campaigns`, {
          ns: TRANSLATE_INSIGHTS_NS,
        }) as unknown as string,
        cell: (info) => {
          const { campaignName } = info.row.original.action;
          return (
            <If
              condition={campaignName.length > MAX_ACTION_NAME_LENGTH}
              fallback={<Text variant="text">{campaignName}</Text>}
            >
              <Tooltip
                isClickable
                isDark
                noArrow
                alignment="start"
                content={campaignName}
                position="top"
              >
                <Text className="max-w-[80%] truncate" variant="text">
                  {campaignName}
                </Text>
              </Tooltip>
            </If>
          );
        },
        meta: { widthClassName: 'w-[14%]' },
      }),
      columnHelper.accessor('action.exeDate', {
        header: t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_HEADER.execution`, {
          ns: TRANSLATE_INSIGHTS_NS,
        }) as unknown as string,
        cell: (info) => {
          const { exeDate } = info.row.original.action;
          const formattedDate = exeDate ? formatDate(exeDate, DateFormat.LongDateOutSeconds) : '';

          return <>{formattedDate}</>;
        },
        meta: { widthClassName: 'w-[9%]' },
      }),
      columnHelper.accessor('action.audience', {
        header: t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_HEADER.audience`, {
          ns: TRANSLATE_INSIGHTS_NS,
        }) as unknown as string,
        cell: (info) => {
          const { audience } = info.row.original.action;
          return (
            formattedNumber(Number(audience), i18n.language as keyof typeof ELanguageMap) ?? '-'
          );
        },
        meta: { widthClassName: 'w-[11%]' },
      }),
      columnHelper.display({
        header: t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_HEADER.insights`, {
          ns: TRANSLATE_INSIGHTS_NS,
        }) as unknown as string,
        cell: (info) => {
          const { insights, channel } = info.row.original.action;
          const isSMSInbox = channel === EChannelsID.SMS;

          const totalSmsSent =
            formattedNumber(
              Number(insights?.totalSmsAmountSent),
              i18n.language as keyof typeof ELanguageMap
            ) ?? '-';
          const totalOpenings =
            formattedNumber(
              Number(insights?.totalOpenings),
              i18n.language as keyof typeof ELanguageMap
            ) ?? '-';
          const totalSends = isSMSInbox ? totalSmsSent : totalOpenings;
          const totalClicks =
            formattedNumber(
              Number(insights?.totalClicks),
              i18n.language as keyof typeof ELanguageMap
            ) ?? '-';
          const totalBounces =
            formattedNumber(
              Number(insights?.totalBounces),
              i18n.language as keyof typeof ELanguageMap
            ) ?? '-';
          const totalFailedSMS =
            formattedNumber(
              Number(insights?.totalSmsFailed),
              i18n.language as keyof typeof ELanguageMap
            ) ?? '-';
          const totalFailed = isSMSInbox ? totalFailedSMS : totalBounces;

          return (
            <div className="-my-2 flex w-full flex-row items-center gap-14 bg-[#F8FAFC] px-4 py-6">
              <span>
                {isSMSInbox
                  ? t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_INSIGHTS.sends`, {
                      ns: TRANSLATE_INSIGHTS_NS,
                    })
                  : t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_INSIGHTS.opens`, {
                      ns: TRANSLATE_INSIGHTS_NS,
                    })}
                : {totalSends}
              </span>
              <span>
                {t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_INSIGHTS.clicks`, {
                  ns: TRANSLATE_INSIGHTS_NS,
                })}
                : {totalClicks}
              </span>
              <span>
                {isSMSInbox
                  ? t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_INSIGHTS.failed`, {
                      ns: TRANSLATE_INSIGHTS_NS,
                    })
                  : t(`${TRANSLATE_INSIGHTS_ACTIONS_SUMMARY}.TABLE_INSIGHTS.bounces`, {
                      ns: TRANSLATE_INSIGHTS_NS,
                    })}
                : {totalFailed}
              </span>
            </div>
          );
        },
        meta: { widthClassName: 'w-[30%]' },
      }),
    ];
  }, [columnHelper, createSimpleEmail, getRoutePathAction, i18n.language, isWhatsAppAction, t]);

  return { columns };
};
