import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button, IconSvg, If, Iframe, Spinner } from '@/lib/v2/components';
import { DeleteIcon } from '@/lib/v2/icons/solid';
import { ModalConfirmation } from '@/lib/v2/organisms';

import { AUTOMATION_PATHS } from '@/modules/AutomationModuleNew/constants';
import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomOpenMessageModal } from '@/modules/CampaignsModule/atoms/actionsAtom';
import ErrorAlert from '@/modules/CampaignsModule/components/actionManagement/EmailAction/components/EmailForm/components/MessageDisplay/ErrorAlert/ErrorAlert';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { IContent } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { atomNewRulePath } from '@/modules/RulesModule/atoms/rules';

interface IMessageDisplay {
  html: IContent;
  isLoading?: boolean;
  onDeleteMessage?: () => void;
  hasOverlay?: boolean;
  disabled?: boolean;
}

const MessageDisplay = ({
  html,
  isLoading,
  onDeleteMessage,
  hasOverlay = true,
  disabled = false,
}: IMessageDisplay) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId, actionId, actionType } = useParams();
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const dragAndDrop = actionInfoEmail ? actionInfoEmail.content.editorVersion === 'beefree' : '';
  const editorRoute = dragAndDrop ? 'editor' : 'html-editor';
  const { pathname } = useLocation();
  const isAutomation = pathname.includes('automation');

  const [openModal, setOpenModal] = useAtom(atomOpenMessageModal);
  const setNewRulePath = useSetAtom(atomNewRulePath);

  const redirectLocation = useMemo(
    () => `${CAMPAIGNS_PATH}/${Number(campaignId)}/${actionType}/${Number(actionId)}`,
    [campaignId, actionId, actionType]
  );

  const isError = html.errors.length > 0;

  const handleEditClick = useCallback(() => {
    setNewRulePath(true);
    if (isAutomation) {
      navigate(
        `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${campaignId}/action/${actionId}/email-trigger/editor`
      );
      return;
    }
    navigate(`${redirectLocation}/${editorRoute}`);
  }, [setNewRulePath, isAutomation, navigate, redirectLocation, editorRoute, campaignId, actionId]);

  const handlePreviewClick = useCallback(() => {
    if (isAutomation) {
      navigate(
        `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${String(campaignId)}/action/${String(
          actionId
        )}/${String(actionType)}/${AUTOMATION_PATHS.PREVIEW}`
      );
      return;
    }
    navigate(`${redirectLocation}/preview`);
  }, [actionId, actionType, campaignId, isAutomation, navigate, redirectLocation]);

  const handleDeleteClick = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  if (isLoading) {
    return <Spinner withoutOverlay />;
  }

  return (
    <>
      <div className="size-full">
        <If condition={isError}>
          <div className="flex flex-col gap-2 px-10 py-2">
            <ErrorAlert errors={html.errors || []} />
          </div>
        </If>
        <div
          className={`relative grid ${
            isError ? 'min-h-[calc(100vh-245px)]' : 'min-h-[calc(100vh-186px)]'
          } place-items-center px-10 ${isError ? 'pb-6' : 'py-6'}`}
        >
          <div className="relative size-full">
            <Iframe withoutOverflow html={html.message} id="message-display-iframe" title="html" />
            <If condition={hasOverlay}>
              <div className="group absolute inset-0 flex items-center justify-center bg-black/80 opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                <div className="flex flex-col items-center gap-6">
                  <Button
                    fullWidth
                    secondary
                    standard
                    disabled={disabled}
                    id="preview-edit__button"
                    size="large"
                    onClick={handleEditClick}
                  >
                    {t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.edit`)}
                  </Button>
                  <Button
                    fullWidth
                    secondary
                    standard
                    id="preview__button"
                    size="large"
                    onClick={handlePreviewClick}
                  >
                    {t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.preview`)}
                  </Button>
                </div>

                <div className="absolute right-8 top-8">
                  <Button
                    secondary
                    standard
                    disabled={disabled}
                    iconLeft={
                      <IconSvg
                        fillColor="gray-dark"
                        height="20"
                        svgComponent={<DeleteIcon />}
                        width="20"
                      />
                    }
                    id="preview-delete__button"
                    size="large"
                    onClick={handleDeleteClick}
                  ></Button>
                </div>
              </div>
            </If>
          </div>
        </div>
      </div>
      <ModalConfirmation
        isLoading={isLoading}
        isOpen={openModal}
        textCancelButton={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.cancel')}
        textConfirmButton={t(`CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.delete`)}
        title={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.modalConfirmation')}
        onClose={handleCloseModal}
        onConfirmButton={onDeleteMessage}
      />
    </>
  );
};

export default MessageDisplay;
