//cspell:ignore mensaje
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import {
  WhatsAppCTATemplate,
  WhatsAppMediaTemplate,
  WhatsAppTemplate,
  WhatsAppTemplateTypes,
} from '@/src/infrastructure/Protocol/Inbox/IInboxService';
import { IWhatsAppFormInfo } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/hooks/useWhatsAppForm';
import {
  TEMPLATE_OPTION,
  TemplateOption,
} from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/constants';
import { useExtractContentSidFromJson } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/hooks/useExtractBodyFromJson';
import { IWhatsAppInfoPayload } from '@/src/modules/CampaignsModule/interfaces/WhatsApp';

import {
  atomInfoWhatsAppPayload,
  atomMediaUrl,
  atomMessageWhatsApp,
} from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import {
  atomIsLoadingWhatsAppTemplates,
  atomSendWhatsAppModalIsOpen,
  atomWhatsAppTemplates,
  atomWhatsAppTemplateSelected,
} from '@/modules/ContactsModule/atoms/WhatsApp';

interface UseTemplateManagementProps {
  setValue: UseFormSetValue<IWhatsAppFormInfo>;
  setInfoOnEvent: (payload: IWhatsAppInfoPayload) => void;
  templateOption: TemplateOption;
}

export const useTemplateManagement = ({
  setValue,
  setInfoOnEvent,
  templateOption,
}: UseTemplateManagementProps) => {
  const [selectedTemplate, setSelectedTemplate] = useAtom(atomWhatsAppTemplateSelected);
  const [messageWhatsApp, setMessageWhatsApp] = useAtom(atomMessageWhatsApp);
  const [infoWhatsAppPayload, setInfoWhatsAppPayload] = useAtom(atomInfoWhatsAppPayload);
  const [modalIsOpen, setModalIsOpen] = useAtom(atomSendWhatsAppModalIsOpen);
  const whatsAppTemplates = useAtomValue(atomWhatsAppTemplates);
  const isLoadingTemplates = useAtomValue(atomIsLoadingWhatsAppTemplates);
  const setMediaUrl = useSetAtom(atomMediaUrl);

  const contentSid = useExtractContentSidFromJson(messageWhatsApp.original);

  useEffect(() => {
    if (templateOption === TEMPLATE_OPTION.CREATE) {
      setMediaUrl('');
      return;
    }

    if (whatsAppTemplates && messageWhatsApp.original && !selectedTemplate) {
      const templateFound = whatsAppTemplates.find((template: WhatsAppTemplate) => {
        const templateType = Object.keys(template.types)[0] as keyof WhatsAppTemplateTypes;
        const templateSid = template.sid;

        if (templateType) {
          return templateSid === contentSid;
        }

        return false;
      });

      if (templateFound) {
        setSelectedTemplate(templateFound);
      }
    }
  }, [
    templateOption,
    whatsAppTemplates,
    contentSid,
    messageWhatsApp.original,
    setSelectedTemplate,
    setMediaUrl,
  ]);

  useEffect(() => {
    if (templateOption === TEMPLATE_OPTION.CREATE) return;

    if (selectedTemplate) {
      const templateType = Object.keys(selectedTemplate.types)[0] as keyof WhatsAppTemplateTypes;
      const templateData = selectedTemplate.types[templateType];

      let messageContent = templateData?.body || '';
      const customVariables: Record<string, string> = selectedTemplate.variables || {};
      const variableRegex = /\$\{\d+#[^}]+\}/g;
      let index = 1;

      messageContent = messageContent.replace(variableRegex, (match) => {
        const twilioVar = `{{${index}}}`;
        customVariables[`variable${index}`] = match;
        index++;
        return twilioVar;
      });

      const messageJSON = JSON.stringify({
        to: '',
        from: '',
        ContentSid: selectedTemplate.sid,
        body: messageContent,
        ContentVariables: Object.keys(customVariables).length > 0 ? customVariables : undefined,
        ...(templateType === 'twilio/media' && {
          mediaUrl: (templateData as WhatsAppMediaTemplate).media[0],
        }),
        ...(templateType === 'twilio/call-to-action' && {
          actions: (templateData as WhatsAppCTATemplate).actions,
        }),
      });

      setValue('message', messageJSON);
      setMessageWhatsApp({
        ...messageWhatsApp,
        original: messageJSON,
      });

      const payload: IWhatsAppInfoPayload = { ...infoWhatsAppPayload, mensaje: messageJSON };
      setInfoOnEvent(payload);
      setInfoWhatsAppPayload(payload);

      if (templateType === 'twilio/media') {
        const mediaTemplate = templateData as WhatsAppMediaTemplate;
        setMediaUrl(mediaTemplate.media[0] || '');
      } else {
        setMediaUrl('');
      }
    }
  }, [selectedTemplate, setInfoOnEvent, setInfoWhatsAppPayload, setMediaUrl, templateOption]);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, [setModalIsOpen]);

  const handleOpenModal = useCallback(() => {
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  const handleDeleteTemplate = useCallback(() => {
    setSelectedTemplate(null);
    setMessageWhatsApp({ ...messageWhatsApp, original: '' });

    const payload: IWhatsAppInfoPayload = { ...infoWhatsAppPayload, mensaje: '' };
    setInfoOnEvent(payload);
    setInfoWhatsAppPayload(payload);
  }, [
    setSelectedTemplate,
    messageWhatsApp,
    setMessageWhatsApp,
    infoWhatsAppPayload,
    setInfoOnEvent,
    setInfoWhatsAppPayload,
  ]);

  return {
    isLoadingTemplates,
    selectedTemplate,
    modalIsOpen,
    handleCloseModal,
    handleOpenModal,
    handleDeleteTemplate,
  };
};
