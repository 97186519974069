import { useCallback, useEffect, useMemo, useState } from 'react';

import { useEnv } from '@/src/application/hooks/util/useEnv';
import configData from '@/src/config.json';
import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { IInboxService } from './IInboxService';
import { InboxService } from './InboxService';
import { StubInboxService } from './StubInboxService';

export const useInboxService = () => {
  const { env } = useEnv();

  const service = useMemo(() => {
    if (env !== 'storybook') {
      const { URL_EMA_APP_V2, JWT_DEV_TOKEN, URL_INBOX_API } = configData.endpoints[env];
      const jwt =
        JWT_DEV_TOKEN !== ''
          ? JWT_DEV_TOKEN
          : (window as Window & typeof globalThis & { __JWTToken: string }).__JWTToken;

      return InboxService.create({
        api: new APIService(''),
        jwt: jwt,
        url_ema_app_v2: URL_EMA_APP_V2,
        url_inbox_api: URL_INBOX_API,
      });
    } else {
      return StubInboxService.create();
    }
  }, [env]);

  return service;
};

export const useInboxGetterService = <T extends keyof IInboxService>(
  method: T,
  ...p: Parameters<IInboxService[T]>
): {
  data: Awaited<ReturnType<IInboxService[T]>> | undefined;
  error: boolean;
  loading: boolean;
  reFetchData: () => void;
} => {
  const service = useInboxService();
  const [data, setData] = useState<Awaited<ReturnType<IInboxService[T]>>>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const params = JSON.stringify(p);

  const fetchData = useCallback(async () => {
    if (!service) throw new Error(`EM_Error The ${typeof service} does not exist`);

    const fn: unknown = service[method];

    if (typeof fn !== 'function')
      throw new Error(`Method '${method}' does not exist on service '${typeof service}'`);

    try {
      setLoading(true);
      const result = await fn.call(
        service,
        ...(JSON.parse(params) as Parameters<IInboxService[T]>)
      );

      if (!result) throw new Error('Error Inbox Service');

      setData(result);
      setLoading(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [service, method, params]);

  const reFetchData = useCallback(() => {
    void fetchData();
  }, [fetchData]);

  useEffect(() => {
    void fetchData();
  }, [method, service, params, fetchData]);

  return { data, error, loading, reFetchData };
};
