export const TRANSLATE_INSIGHTS_CONTACTS = 'CONTACTS_GRAPH';
export const TRANSLATE_INSIGHTS_ACTIONS_SUMMARY = 'ACTIONS_SUMMARY';

export const TRANSLATE_INSIGHTS_NS = 'insights';

export const GROUPS_LIMIT = 200;

export const BAR_GRAPH_CONFIG = {
  labels: {
    fontSize: '12px',
    fontWeight: 400,
  },
  bar: {
    barSize: 40,
    radius: [4, 4, 0, 0],
    fill: {
      contacts: '#5594FA',
      contacted: '#81CCF7',
    },
  },
};

export enum GRAPH_EVOLUTION_MONTHS {
  THREE = 3,
  SIX = 6,
  TWELVE = 12,
}

export type TBarRadius = [number, number, number, number];
