import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BAR_GRAPH_CONFIG, TRANSLATE_INSIGHTS_CONTACTS } from '@/modules/InsightsModule/constants';

const ContactsGraphLegend = () => {
  const { t } = useTranslation(['insights']);
  return (
    <div className="absolute left-4 top-6 flex gap-2">
      <div className="flex items-center gap-1">
        <span className="text-14 text-gray-600">
          {t(`${TRANSLATE_INSIGHTS_CONTACTS}.contacts`)}
        </span>
        <div
          className="size-3 rounded-sm"
          style={{ backgroundColor: BAR_GRAPH_CONFIG.bar.fill.contacts }}
        />
      </div>
      <div className="flex items-center gap-1">
        <span className="text-14 text-gray-600">
          {t(`${TRANSLATE_INSIGHTS_CONTACTS}.contacted`)}
        </span>
        <div
          className="size-3 rounded-sm"
          style={{ backgroundColor: BAR_GRAPH_CONFIG.bar.fill.contacted }}
        />
      </div>
    </div>
  );
};

export default memo(ContactsGraphLegend);
