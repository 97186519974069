import { useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useBeefreeService } from '@/src/infrastructure/Protocol/Beefree/useBeefreeService';
import {
  atomActionPathBeefreeNav,
  atomFiltersMyCatalog,
  atomMyCatalog,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { ChannelInfoByID } from '@/src/modules/CampaignsModule/components/CampaignActions/ActionsTable/hooks/useActionsTableColumns';
import {
  IEmailOfCatalog,
  IEmblueMyCatalogTemplatesResponse,
} from '@/src/modules/CampaignsModule/interfaces/Beefree';

import { useActionData } from './useActionData';
import { useBeefreeLogger } from './useBeefreeLogger';
import { useModalPreview } from './useModalPreview';

import { useNavigateAndBack } from '@/modules/AutomationModuleNew/hooks/useNavigateAndBack';

export const useMyTemplates = () => {
  const masonryRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<HTMLDivElement>(null);
  const [showModalDelete, setShowModalDelete] = useState<IEmailOfCatalog | null>(null);
  const [catalog, setCatalog] = useAtom(atomMyCatalog);
  const [filters, setFilters] = useAtom(atomFiltersMyCatalog);
  const actionPathBeefreeNav = useAtomValue(atomActionPathBeefreeNav);
  const [hasMore, setHasMore] = useState(true);
  const serviceBeefree = useBeefreeService();
  const { setShowModalPreview } = useModalPreview();
  const { t } = useTranslation();
  const { actionId } = useParams();
  const { action, refetch } = useActionData(parseInt(actionId || '0'));
  const navigate = useNavigate();
  const [, setIsLoadingVisible] = useState(false);
  const { logger } = useBeefreeLogger();
  const { pathname } = useLocation();
  const isAutomation = pathname.includes('automation');
  const { getBackUrlAndRemoveSS } = useNavigateAndBack();

  const getCatalog = useCallback(
    async (initial = false) => {
      setIsLoadingVisible(false);

      const page = initial ? 0 : filters.page + 1;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response = await serviceBeefree.getMyTemplates({
        ...filters,
        page,
      });

      if (response === null) return;

      const { data, pagination } = response as IEmblueMyCatalogTemplatesResponse;

      setCatalog((prevCatalog) => (initial ? data : (prevCatalog ?? []).concat(data)));
      const totalPages = Math.ceil(pagination.total / filters.limit);

      if (initial && page > 0) {
        setFilters({
          ...filters,
          page: 0,
        });
      } else {
        setFilters({
          ...filters,
          page,
        });
      }

      if (page >= totalPages || totalPages <= 1) {
        hasMore && setHasMore(false);
      } else {
        !hasMore && setHasMore(true);
      }
    },
    [filters, hasMore, serviceBeefree, setCatalog, setFilters]
  );

  const handleUseEmail = useCallback(
    async (id: number) => {
      if (!actionId) return;

      const response = await serviceBeefree.useTemplate(id, parseInt(actionId));

      if (response) {
        await logger('useMyTemplate', 'action');
      } else {
        await logger('useMyTemplate', 'error');
      }

      if (response && typeof action?.channel === 'number') {
        const { route } = ChannelInfoByID[action?.channel as keyof typeof ChannelInfoByID];
        if (isAutomation) {
          const backUrl = getBackUrlAndRemoveSS();
          navigate(backUrl);
          return;
        }
        navigate(
          `/v2/campaigns/${action.campaignId}/${actionPathBeefreeNav || route}/${
            action?.actionId
          }/editor`
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionId, serviceBeefree, action, navigate]
  );

  const handleDeleteEmail = useCallback(
    async (id: number) => {
      const success = (await serviceBeefree.deleteTemplate(id)) || false;

      if (success) {
        await logger('deleteMyTemplate', 'action');
      } else {
        await logger('deleteMyTemplate', 'error');
      }

      setShowModalDelete(null);

      toast({
        title: success
          ? t('CATALOG_MODAL_DELETE_NOTIFY.success.title')
          : t('CATALOG_MODAL_DELETE_NOTIFY.error.title'),
        body: success
          ? t('CATALOG_MODAL_DELETE_NOTIFY.success.body')
          : t('CATALOG_MODAL_DELETE_NOTIFY.error.body'),
        variant: success ? 'success' : 'error',
      });

      setFilters(RESET);
      await getCatalog(true);
    },
    [getCatalog, logger, serviceBeefree, setFilters, t]
  );

  const handlePreviewEmail = useCallback(
    (template: IEmailOfCatalog) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(template.htmlData, 'text/html');
      const styleElement = doc.createElement('style');
      styleElement.textContent = 'body, html { scrollbar-width: none; }';
      doc.body.prepend(styleElement);

      setShowModalPreview({
        show: true,
        html: doc.documentElement.outerHTML,
      });
    },
    [setShowModalPreview]
  );

  useEffect(() => {
    if (action === null) refetch();
  }, [action, refetch]);

  useEffect(() => {
    void getCatalog(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    catalog,
    masonryRef,
    loadingRef,
    handleUseEmail,
    handleDeleteEmail,
    handlePreviewEmail,
    showModalDelete,
    setShowModalDelete,
    hasMore,
    getCatalog,
  };
};
