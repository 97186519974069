import { CardSection, Flex } from '@/lib/v2/components';

import { RulesEmailAction } from './RulesEmailAction';

const RulesEmailMain = () => {
  return (
    <div className="relative z-0 w-full bg-white">
      <CardSection noPadding>
        <Flex column>
          <RulesEmailAction />
        </Flex>
      </CardSection>
    </div>
  );
};

export default RulesEmailMain;
