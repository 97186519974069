import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';

import { SenderStatus } from '@/modules/SendersModule/interfaces/Senders';

export interface NodeData {
  parentId?: string;
}

export enum AddOperationTypes {
  condition = 'condition',
  action = 'action',
  trigger = 'trigger',
  placeholder = 'placeholder',
}

//cspell:disable
export interface IRulesV1Response {
  activo?: boolean;
  acumuladaCantidad: number | null;
  campoDTO: FieldDTO;
  campoEmpresaDTO: FieldDTO[];
  camposPersonalizadosDTO: CustomFieldsDTO[];
  desencadenante: string;
  elementoId: number;
  elementosDTO: any[];
  elementosHnosCantidad: number;
  enviarCadaAnio: null;
  envioRecurrente: boolean | null;
  fechaEnvio: string;
  group_rules: number | null;
  group_rules_in: number | null;
  group_rules_type: string;
  grupoDTO: FieldGroupDTO | null;
  horaEnvio: string | null;
  landingPageDTO: null;
  landingPageErrorDTO: null;
  redireccionamientoFinal: string;
  redireccionamientoFinalError: string;
  tag_id: number[];
  tags_DTO: TagDTO[];
  tipo: string;
  urlDestino: null;
  urlDestinoError: null;
  urlExterna: string;
}

export interface PayloadSaveRules {
  acumuladaCantidad: number | null;
  campoDTO: FieldDTO;
  camposPersonalizadosDTO: CustomFieldsDTO[] | null;
  desencadenante: string;
  elementoId: number;
  elementosHnosCantidad: number;
  enviarCadaAnio: null;
  envioRecurrente: boolean | null;
  fechaEnvio: string;
  group_rules?: number | null;
  group_rules_in?: number | null;
  group_rules_type?: string;
  grupoDTO?: FieldGroupDTO | null;
  horaEnvio?: string | null;
  landingPageDTO?: null;
  landingPageErrorDTO?: null;
  redireccionamientoFinal?: string;
  redireccionamientoFinalError?: string;
  tag_id: number[];
  tipo?: string;
  urlDestino: string | null;
  urlDestinoError: string | null;
}

export interface FieldDTO {
  id: number;
  nombre: string;
  integracion_id: number | null;
}

export interface CustomFieldsDTO {
  id: number;
  requerido: boolean;
}

export interface FieldGroupDTO {
  id: number;
  nombre: string;
}

interface TagDTO {
  cantidadActividad: number;
  cantidadFrecuencia: number;
  cantidadUsuarios: number;
  capaId: number;
  categoriaNombre: string | null;
  color: string | null;
  contenedorId: number | null;
  contenido: string | null;
  contenidoTipo: 'HTML' | 'URL' | null;
  nombre: string;
  orderDefault: number | null;
  tagCategoriaId: number | null;
  tagId: number;
  ultimoUso: string;
}

interface GroupRecipientsV1 {
  id: string;
  nombre: string;
  tipo: string;
}

export interface IRulesRecipientsResponseV1 {
  elementoId: number;
  grupos: GroupRecipientsV1[];
  listas: unknown[];
}
//cspell:enable

export interface TriggerRuleOptions {
  trigger?: Option;
  customField?: Option;
  days?: Option;
  uniqueExecution?: string;
  frequency?: Option;
  execution?: Option;
  period?: Option;
  hours?: Option;
  minutes?: Option;
  actionId?: number;
  repeatEmails?: Option;
  groupContacts?: OptionExternal | undefined;
  touchRules?: boolean;

  redirectSuccess?: Option | null;
  destinationSuccessUrl?: string;
  redirectError?: Option | null;
  destinationErrorUrl?: string;
  externalUrl?: string | null;

  customFieldsDTO?: CustomFieldsDTO[];
  tagTypeField?: Option;
  tagsGroupField?: Option[];
}

export interface ConditionOptions {
  selectedGroupOrSegment?: OptionExternal | undefined;
  presenceCondition?: Option;
  touchRules?: boolean;
}

export type RulesOptions = TriggerRuleOptions & ConditionOptions;

export enum DataTypeCustomField {
  DATE = 'date',
}

export enum FormTypes {
  RULE = 'rule',
  CONDITION = 'condition',
  ACTION = 'action',
}

//cspell:disable
export enum RuleTypes {
  DATE = 'Fecha desencadenante',
  TAG = 'Tag asociado',
  EXTERNAL_CALL = 'Llamada externa a una URL',
  API_INTEGRATOR = 'Api Integrador',
  WEB_FORM = 'Formulario',
  FLOW_TEMPLATE = 'Flows',
}
//cspell:enable

export interface IFormProps<T extends FieldValues> {
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
  control: Control<T>;
  watch?: UseFormWatch<T>;
  RULES_API?: Record<string, { required: string }>;
  RULES_DATE?: Record<string, { required: string }>;
}

export type IRulesEmailFormInfo = {
  sender: {
    id: string | number;
    name: string;
    value: string;
    iconStatus?: SenderStatus;
  };
  subject: string;
  firstLine: string;
};
export interface IRulesEmailInfoPayload {
  senderId: number;
  subject: string;
  firstLine: string;
}

//cspell:disable
export interface PayloadSaveCloseDateRule {
  activoIndefinido: boolean;
  elementoId: number;
  hora: string | number | null;
  milis: number | null;
  minutos: string | number | null;
}
//cspell:enable

export interface IRuleForm {
  trigger: Option;
  customField: Option;
  frequency: Option;
  execution: Option;
  days: Option;
  uniqueExecution: string;
  period: Option;
  hours: Option;
  minutes: Option;
  actionId: number;
  repeatEmails: Option;
  groupContacts: OptionExternal;
  touchRules: boolean;

  redirectSuccess: Option;
  destinationSuccessUrl: string;
  redirectError: Option;
  destinationErrorUrl: string;

  tagTypeField: Option;
  tagsGroupField: Option[];
  customFieldsDTO?: CustomFieldsDTO[];
}

export interface ITagListItem {
  tag: ITag;
}

export interface ITagListRules {
  list: ITagListItem[];
}

interface IFieldMetadata {
  optionType: string | null;
  internalColumn: string;
  internalId: number;
  internalNumber: number;
  defaultField: boolean;
  fieldType: string;
  defaultValues?: string[];
}

export interface IIncomingField {
  id: number;
  label: string;
  value: number;
  data: IFieldMetadata;
  type: string;
}

export interface IFieldStatus {
  id: number;
  switchStatus: boolean;
  requiredStatus: boolean;
}

export interface ISelectCountryOption {
  value: string;
  label: string;
}

export interface IActionNodeInfo {
  partial: boolean;
  complete: boolean;
  empty: boolean;
}
